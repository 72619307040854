import * as UserActions from './user.actions';
import {handleError} from '../helpers';

type Action = UserActions.All;

export interface UserState {
  loading: boolean;
  entities: Array<any>;
  entitiesById: any;
  searchResults: Array<any>;
  next: string;
  error: string;
}

const initialState: UserState = {
  loading: false,
  entities: null,
  entitiesById: null,
  searchResults: null,
  next: null,
  error: null
};

export function reducer(state: UserState = initialState, {type, payload}: Action): UserState {
  switch (type) {
    case UserActions.LOAD_USERS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case UserActions.LOAD_USERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: payload.results,
        next: payload.next
      });
    case UserActions.LOAD_USERS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case UserActions.LOAD_MORE_USERS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case UserActions.LOAD_MORE_USERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: [...state.entities, ...payload.results],
        next: payload.next
      });
    case UserActions.LOAD_MORE_USERS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case UserActions.SEARCH_USER:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case UserActions.SEARCH_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        searchResults: payload.results,
        error: null
      });
    case UserActions.SEARCH_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case UserActions.CLEAR_USER_SEARCH:
      return Object.assign({}, state, {
        loading: false,
        searchResults: null,
        error: null
      });
    case UserActions.LOAD_USER_BY_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case UserActions.LOAD_USER_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesById: Object.assign({}, state.entitiesById, {
          [payload.id]: payload
        }),
        error: null
      });
    case UserActions.LOAD_USER_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case UserActions.UPDATE_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: (payload.error) ? payload.error : payload,
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: UserState) => state.loading;
export const getEntities = (state: UserState) => state.entities;
export const getSearchResults = (state: UserState) => state.searchResults;
export const getEntitiesById = (state: UserState) => state.entitiesById;
export const getError = (state: UserState) => state.error;
export const getNext = (state: UserState) => state.next;

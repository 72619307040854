import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as StatisticsActions from './statistics.actions';
import {StatisticsService} from '../../core/statistics.service';

@Injectable()
export class StatisticsEffects {
  @Effect() userStats$: Observable<Action> = this.actions$.pipe(
    ofType(StatisticsActions.LOAD_USER_STATISTICS),
    switchMap(_ => {
      return this._service.getUserStatistics()
        .pipe(
          map(state => new StatisticsActions.LoadUserStatisticsSuccess(state)),
          catchError(error => of(new StatisticsActions.LoadUserStatisticsFailure(error)))
        );
    })
  );

  @Effect() institutionStats$: Observable<Action> = this.actions$.pipe(
    ofType(StatisticsActions.LOAD_INSTITUTION_STATISTICS),
    switchMap(_ => {
      return this._service.getInstitutionStatistics()
        .pipe(
          map(state => new StatisticsActions.LoadInstitutionStatisticsSuccess(state)),
          catchError(error => of(new StatisticsActions.LoadInstitutionStatisticsFailure(error)))
        );
    })
  );

  @Effect() loadServiceReceiverList$: Observable<Action> = this.actions$.pipe(
    ofType(StatisticsActions.LOAD_SERVICE_RECEIVER_LIST),
    switchMap(_ => {
      return this._service.loadServiceReceiverList()
        .pipe(
          map(state => new StatisticsActions.LoadServiceReceiverListSuccess(state)),
          catchError(error => of(new StatisticsActions.LoadServiceReceiverListFailure(error)))
        );
    })
  );

  constructor(private actions$: Actions, private _service: StatisticsService) {}
}

import * as AddressActions from './address.actions';
import {handleError} from '../helpers';
import {Address} from '../../interfaces';

type Action = AddressActions.All;

export interface AddressState {
  loading: boolean;
  error: string;
  searchResults: Address[];
  entitiesById: any;
  countries: string[];
}

const initialState: AddressState = {
  loading: false,
  error: null,
  searchResults: null,
  entitiesById: null,
  countries: null
};

export function reducer(state: AddressState = initialState, {type, payload}: Action): AddressState {
  switch (type) {
    case AddressActions.SEARCH_ADDRESS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case AddressActions.SEARCH_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        searchResults: payload.results
      });
    case AddressActions.SEARCH_ADDRESS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case AddressActions.CLEAR_ADDRESS_SEARCH:
      return Object.assign({}, state, {
        loading: false,
        searchResults: null,
        error: null
      });
    case AddressActions.LOAD_ADDRESS_BY_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case AddressActions.LOAD_ADDRESS_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesById: Object.assign({}, state.entitiesById, {
          [payload.id]: payload
        })
      });
    case AddressActions.LOAD_ADDRESS_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case AddressActions.LOAD_COUNTRIES:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case AddressActions.LOAD_COUNTRIES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        countries: payload
      });
    case AddressActions.LOAD_COUNTRIES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: AddressState) => state.loading;
export const getSearchResults = (state: AddressState) => state.searchResults;
export const getEntitiesById = (state: AddressState) => state.entitiesById;
export const getCountries = (state: AddressState) => state.countries;

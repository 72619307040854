import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as EulaActions from './eula.actions';
import {EulaService} from '../../core/eula.service';

@Injectable()
export class EulaEffects {
  @Effect() loadEulas$: Observable<Action> = this.actions$.pipe(
    ofType(EulaActions.LOAD_EULA),
    switchMap(_ => {
      return this._service.load()
        .pipe(
          map(state => new EulaActions.LoadEulaSuccess(state)),
          catchError(error => of(new EulaActions.LoadEulaFailure(error)))
        );
    })
  );

  @Effect() loadEulaByVersion$: Observable<Action> = this.actions$.pipe(
    ofType(EulaActions.LOAD_EULA_BY_VERSION),
    map((action: EulaActions.LoadEulaByVersion) => action.payload),
    switchMap(payload => {
      return this._service.loadByVersion(payload)
        .pipe(
          map(state => new EulaActions.LoadEulaByVersionSuccess(state)),
          catchError(error => of(new EulaActions.LoadEulaByVersionFailure(error)))
        );
    })
  );

  @Effect() loadEulaByVersionAndLanguage$: Observable<Action> = this.actions$.pipe(
    ofType(EulaActions.LOAD_EULA_BY_VERSION_AND_LANGUAGE),
    map((action: EulaActions.LoadEulaByVersionAndLanguage) => action.payload),
    switchMap(payload => {
      return this._service.loadByVersionAndLanguage(payload.version_number, payload.language)
        .pipe(
          map(state => new EulaActions.LoadEulaByVersionAndLanguageSuccess(state)),
          catchError(error => of(new EulaActions.LoadEulaByVersionAndLanguageFailure(error)))
        );
    })
  );

  constructor(private actions$: Actions, private _service: EulaService) {}
}

import {Action} from '@ngrx/store';
import {FormType} from '../../enum';

export const USER_FORM_ERROR = 'USER_FORM_ERROR';
export const INSTITUTION_FORM_ERROR = 'INSTITUTION_FORM_ERROR';
export const GROUP_FORM_ERROR = 'GROUP_FORM_ERROR';
export const RELATION_FORM_ERROR = 'RELATION_FORM_ERROR';
export const CLEAR_FORM_ERROR = 'CLEAR_FORM_ERROR';

export class UserFormError implements Action {
  readonly type = USER_FORM_ERROR;

  constructor(public payload: any) {}
}

export class InstitutionFormError implements Action {
  readonly type = INSTITUTION_FORM_ERROR;

  constructor(public payload: any) {}
}

export class GroupFormError implements Action {
  readonly type = GROUP_FORM_ERROR;

  constructor(public payload: any) {}
}

export class RelationFormError implements Action {
  readonly type = RELATION_FORM_ERROR;

  constructor(public payload: any) {}
}

export class ClearFormError implements Action {
  readonly type = CLEAR_FORM_ERROR;

  constructor(public payload: FormType) {}
}

export type All = UserFormError | InstitutionFormError | GroupFormError | RelationFormError | ClearFormError;

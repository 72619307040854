import * as StatisticsActions from './statistics.actions';

type Action = StatisticsActions.All;

export interface StatisticsState {
  loading: boolean;
  users: any;
  institutions: any;
  serviceReceiverList: any[];
  error: string;
}

const initialState = {
  loading: false,
  users: null,
  institutions: null,
  serviceReceiverList: null,
  error: null
};

export function reducer(state: StatisticsState = initialState, {type, payload}: Action): StatisticsState {
  switch (type) {
    case StatisticsActions.LOAD_USER_STATISTICS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case StatisticsActions.LOAD_USER_STATISTICS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        users: payload
      });
    case StatisticsActions.LOAD_USER_STATISTICS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case StatisticsActions.LOAD_INSTITUTION_STATISTICS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case StatisticsActions.LOAD_INSTITUTION_STATISTICS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        institutions: payload
      });
    case StatisticsActions.LOAD_INSTITUTION_STATISTICS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case StatisticsActions.LOAD_SERVICE_RECEIVER_LIST:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case StatisticsActions.LOAD_SERVICE_RECEIVER_LIST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        serviceReceiverList: payload
      });
    case StatisticsActions.LOAD_SERVICE_RECEIVER_LIST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: StatisticsState) => state.loading;
export const getUsers = (state: StatisticsState) => state.users;
export const getInstitutions = (state: StatisticsState) => state.institutions;
export const getServiceReceiverList = (state: StatisticsState) => state.serviceReceiverList;

import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';
import {JcRoute} from './jc-route';

const routes: JcRoute[] = [
  {path: 'login', loadChildren: './login/login.module#LoginModule', name: 'Login'},
  {path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule', name: 'Dashboard'},
  {path: 'users', loadChildren: './users/users.module#UsersModule', name: 'Users'},
  {path: 'institutions', loadChildren: './institutions/institutions.module#InstitutionsModule', name: 'Institutions'},
  {path: 'groups', loadChildren: './groups/groups.module#GroupsModule', name: 'Groups'},
  {path: 'municipalities', loadChildren: './municipalities/municipalities.module#MunicipalitiesModule', name: 'Municipalities'},
  {path: 'insight', loadChildren: './insight/insight.module#InsightModule', name: 'Insight'},
  {path: 'user-feedback', loadChildren: './user-feedback/user-feedback.module#UserFeedbackModule', name: 'User Feedback'},
  {path: 'eula', loadChildren: './eula/eula.module#EulaModule', name: 'EULA'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

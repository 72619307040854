import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Relation} from '../interfaces';

@Injectable()
export class UserService extends ApiService {

  constructor(private _http: HttpClient, protected _ls: LocalStorage) {
    super(_ls);
  }

  loadUsers(limit = 10, offset = 0): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users?limit=${limit}&offset=${offset}`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers}))
      );
  }

  loadMore(url: string): Observable<any> {
    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers}))
      );
  }

  searchUsers(term: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users/search?term=${term}`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers}))
      );
  }

  loadUserById(userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users/${userId}`;

    let headers: HttpHeaders;

    return this._getAuthHeaders()
      .pipe(
        switchMap((h: HttpHeaders) => {
          headers = h;
          return this._http.get(url, {headers: headers});
        }),
        switchMap((user: any) => {
          const profileUrl = `${environment['API_URL']}${environment['API_VERSION']}users/${user.id}/profile`;
          return this._http.get(profileUrl, {headers: headers})
            .pipe(
              map(profile => Object.assign({}, user, {userprofile: profile}))
            );
        })
      );
  }

  updateUser(user: any): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users/${user.id}`;
    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.patch(url, user, {headers: headers}))
      );
  }

  updateUserProfile(userId: string, userprofile: any): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users/${userId}/profile`;
    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.patch(url, userprofile, {headers: headers}))
      );
  }

  createUser(user: any): Observable<any> {
    const userprofile = Object.assign({}, user.userprofile);
    delete user.userprofile;

    const createUrl = `${environment['API_URL']}${environment['API_VERSION']}users`;
    let headers: HttpHeaders;

    return this._getAuthHeaders()
      .pipe(
        switchMap((h: HttpHeaders) => {
          headers = h;
          return this._http.post(createUrl, user, {headers: headers});
        }),
        switchMap((newUser: any) => this.updateUserProfile(newUser.id, userprofile)),
      );
  }

  loadUserRelations(userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users/${userId}/relations`;
    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
        map(relations => ({
          user_id: userId,
          results: relations
        }))
      );
  }

  createRelation(relation: Relation): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}relations`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.post(url, relation, {headers: headers}))
      );
  }

  removeRelation(relationId: string, userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}relations/${relationId}`;
    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.delete(url, {headers: headers})),
        map(_ => ({
          relation_id: relationId,
          user_id: userId
        }))
      );
  }
}

import * as MunicipalityActions from './municipality.actions';
import {addEntity, handleError, removeEntity} from '../helpers';
import {Municipality} from '../../interfaces';

type Action = MunicipalityActions.All;

export interface MunicipalityState {
  loading: boolean;
  inactiveEntities: Municipality[];
  activeEntities: Municipality[];
  error: string;
  searchResults: Municipality[];
  entitiesById: any;
  all: any[];
}

const initialState: MunicipalityState = {
  loading: false,
  inactiveEntities: null,
  activeEntities: null,
  error: null,
  searchResults: null,
  entitiesById: null,
  all: null,
};

export function reducer(state: MunicipalityState = initialState, {type, payload}: Action): MunicipalityState {
  switch (type) {
    case MunicipalityActions.LOAD_MUNICIPALITIES:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case MunicipalityActions.LOAD_MUNICIPALITIES_SUCCESS:
      const active1 = payload.results.filter(municipality => municipality.is_active);
      const inactive1 = payload.results.filter(municipality => !municipality.is_active);

      return Object.assign({}, state, {
        loading: false,
        activeEntities: active1,
        inactiveEntities: inactive1,
        all: [...active1, ...inactive1],
      });
    case MunicipalityActions.LOAD_MUNICIPALITIES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case MunicipalityActions.LOAD_MORE_MUNICIPALITIES:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case MunicipalityActions.LOAD_MORE_MUNICIPALITIES_SUCCESS:
      const active2 = payload.results.filter(municipality => municipality.is_active);
      const inactive2 = payload.results.filter(municipality => !municipality.is_active);

      return Object.assign({}, state, {
        loading: false,
        activeEntities: [...state.activeEntities, ...active2],
        inactiveEntities: [...state.activeEntities, ...inactive2],
        all: [...active2, ...inactive2],
      });
    case MunicipalityActions.LOAD_MORE_MUNICIPALITIES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case MunicipalityActions.LOAD_MUNICIPALIY_BY_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case MunicipalityActions.LOAD_MUNICIPALIY_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesById: Object.assign({}, state.entitiesById, {
          [payload.id]: payload
        }),
      });
    case MunicipalityActions.LOAD_MUNICIPALIY_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case MunicipalityActions.UPDATE_MUNICIPALITY:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case MunicipalityActions.UPDATE_MUNICIPALITY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        activeEntities: (!payload.is_active) ? removeEntity(state.activeEntities, payload) : addEntity(state.activeEntities, payload),
        inactiveEntities: (payload.is_active) ? removeEntity(state.inactiveEntities, payload) : addEntity(state.inactiveEntities, payload),
        entitiesById: Object.assign({}, state.entitiesById, {
          [payload.id]: payload
        })
      });
    case MunicipalityActions.UPDATE_MUNICIPALITY_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: MunicipalityState) => state.loading;
export const getActiveEntities = (state: MunicipalityState) => state.activeEntities;
export const getInactiveEntities = (state: MunicipalityState) => state.inactiveEntities;
export const getEntitiesById = (state: MunicipalityState) => state.entitiesById;
export const getAll = (state: MunicipalityState) => state.all;

import {Action} from '@ngrx/store';
import {Relation} from '../../interfaces';

export const LOAD_RELATIONS_BY_USER = 'LOAD_RELATIONS_BY_USER';
export const LOAD_RELATIONS_BY_USER_SUCCESS = 'LOAD_RELATIONS_BY_USER_SUCCESS';
export const LOAD_RELATIONS_BY_USER_FAILURE = 'LOAD_RELATIONS_BY_USER_FAILURE';
export const REMOVE_RELATION = 'REMOVE_RELATION';
export const REMOVE_RELATION_SUCCESS = 'REMOVE_RELATION_SUCCESS';
export const REMOVE_RELATION_FAILURE = 'REMOVE_RELATION_FAILURE';
export const RELATION_CREATED = 'RELATION_CREATED';

export class LoadRelationsByUser implements Action {
  readonly type = LOAD_RELATIONS_BY_USER;

  constructor(public payload: string) {}
}

export class LoadRelationsByUserSuccess implements Action {
  readonly type = LOAD_RELATIONS_BY_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadRelationsByUserFailure implements Action {
  readonly type = LOAD_RELATIONS_BY_USER_FAILURE;

  constructor(public payload: string) {}
}

export class RemoveRelation implements Action {
  readonly type = REMOVE_RELATION;

  constructor(public payload: {relationId: string, userId: string}) {}
}

export class RemoveRelationSuccess implements Action {
  readonly type = REMOVE_RELATION_SUCCESS;

  constructor(public payload: any) {}
}

export class RemoveRelationFailure implements Action {
  readonly type = REMOVE_RELATION_FAILURE;

  constructor(public payload: string) {}
}

export class RelationCreated implements Action {
  readonly type = RELATION_CREATED;

  constructor(public payload: Relation) {}
}

export type All = LoadRelationsByUser | LoadRelationsByUserSuccess | LoadRelationsByUserFailure | RemoveRelation | RemoveRelationSuccess |
  RemoveRelationFailure | RelationCreated;

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import * as MunicipalityActions from './municipality.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {MunicipalityService} from '../../core/municipalities.service';
import {ToastError, ToastSuccess} from '../toast/toast.actions';

@Injectable()
export class MunicipalityEffects {
  @Effect() loadMunicipalities$: Observable<Action> = this._actions$.pipe(
    ofType(MunicipalityActions.LOAD_MUNICIPALITIES),
    map((action: MunicipalityActions.LoadMunicipalities) => action.payload),
    switchMap(payload => {
      return this._service.loadByCountry(payload.country)
        .pipe(
          map(state => new MunicipalityActions.LoadMunicipalitiesSuccess(state)),
          catchError(error => of(new MunicipalityActions.LoadMunicipalitiesFailure(error)))
        );
    })
  );

  @Effect() loadMoreMunicipalities$: Observable<Action> = this._actions$.pipe(
    ofType(MunicipalityActions.LOAD_MORE_MUNICIPALITIES),
    map((action: MunicipalityActions.LoadMoreMunicipalities) => action.payload),
    switchMap(payload => {
      return this._service.loadMoreByCountry(payload)
        .pipe(
          map(state => new MunicipalityActions.LoadMoreMunicipalitiesSuccess(state)),
          catchError(error => of(new MunicipalityActions.LoadMoreMunicipalitiesFailure(error)))
        );
    })
  );

  @Effect() loadMunicipalityById$: Observable<Action> = this._actions$.pipe(
    ofType(MunicipalityActions.LOAD_MUNICIPALIY_BY_ID),
    map((action: MunicipalityActions.LoadMunicipalityById) => action.payload),
    switchMap(payload => {
      return this._service.loadById(payload)
        .pipe(
          map(state => new MunicipalityActions.LoadMunicipalityByIdSuccess(state)),
          catchError(error => of(new MunicipalityActions.LoadMunicipalityByIdFailure(error)))
        );
    })
  );

  @Effect() updateMunicipality$: Observable<Action> = this._actions$.pipe(
    ofType(MunicipalityActions.UPDATE_MUNICIPALITY),
    map((action: MunicipalityActions.UpdateMunicipality) => action.payload),
    switchMap(payload => {
      return this._service.update(payload)
        .pipe(
          map(state => new MunicipalityActions.UpdateMunicipalitySuccess(state)),
          catchError(error => of(new MunicipalityActions.UpdateMunicipalityFailure(error)))
        );
    })
  );

  @Effect() updateMunicipalitySuccess$: Observable<Action> = this._actions$.pipe(
    ofType(MunicipalityActions.UPDATE_MUNICIPALITY_SUCCESS),
    map(() => new ToastSuccess('Municipality updated!'))
  );

  @Effect() updateMunicipalityFailure$: Observable<Action> = this._actions$.pipe(
    ofType(MunicipalityActions.UPDATE_MUNICIPALITY_FAILURE),
    map(() => new ToastError('Unable to update municipality!'))
  );

  constructor(private _actions$: Actions, private _service: MunicipalityService) {}
}

import {Action} from '@ngrx/store';

export const LOAD_USER_FEEDBACK = 'LOAD_USER_FEEDBACK';
export const LOAD_USER_FEEDBACK_SUCCESS = 'LOAD_USER_FEEDBACK_SUCCESS';
export const LOAD_USER_FEEDBACK_FAILURE = 'LOAD_USER_FEEDBACK_FAILURE';
export const LOAD_USER_FEEDBACK_BY_ID = 'LOAD_USER_FEEDBACK_BY_ID';
export const LOAD_USER_FEEDBACK_BY_ID_SUCCESS = 'LOAD_USER_FEEDBACK_BY_ID_SUCCESS';
export const LOAD_USER_FEEDBACK_BY_ID_FAILURE = 'LOAD_USER_FEEDBACK_BY_ID_FAILURE';
export const ADD_USER_FEEDBACK_HISTORY = 'ADD_USER_FEEDBACK_HISTORY';
export const ADD_USER_FEEDBACK_HISTORY_SUCCESS = 'ADD_USER_FEEDBACK_HISTORY_SUCCESS';
export const ADD_USER_FEEDBACK_HISTORY_FAILURE = 'ADD_USER_FEEDBACK_HISTORY_FAILURE';
export const LOAD_USER_FEEDBACK_HISTORY = 'LOAD_USER_FEEDBACK_HISTORY';
export const LOAD_USER_FEEDBACK_HISTORY_SUCCESS = 'LOAD_USER_FEEDBACK_HISTORY_SUCCESS';
export const LOAD_USER_FEEDBACK_HISTORY_FAILURE = 'LOAD_USER_FEEDBACK_HISTORY_FAILURE';
export const UPDATE_USER_FEEDBACK = 'UPDATE_USER_FEEDBACK';
export const UPDATE_USER_FEEDBACK_SUCCESS = 'UPDATE_USER_FEEDBACK_SUCCESS';
export const UPDATE_USER_FEEDBACK_FAILURE = 'UPDATE_USER_FEEDBACK_FAILURE';
export const LOAD_USER_FEEDBACK_EVENTS = 'LOAD_USER_FEEDBACK_EVENTS';
export const LOAD_USER_FEEDBACK_EVENTS_SUCCESS = 'LOAD_USER_FEEDBACK_EVENTS_SUCCESS';
export const LOAD_USER_FEEDBACK_EVENTS_FAILURE = 'LOAD_USER_FEEDBACK_EVENTS_FAILURE';

export class LoadUserFeedback implements Action {
  readonly type = LOAD_USER_FEEDBACK;

  constructor(public payload?: string) {}
}

export class LoadUserFeedbackSuccess implements Action {
  readonly type = LOAD_USER_FEEDBACK_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadUserFeedbackFailure implements Action {
  readonly type = LOAD_USER_FEEDBACK_FAILURE;

  constructor(public payload: string) {}
}

export class LoadUserFeedbackById implements Action {
  readonly type = LOAD_USER_FEEDBACK_BY_ID;

  constructor(public payload: string) {}
}

export class LoadUserFeedbackByIdSuccess implements Action {
  readonly type = LOAD_USER_FEEDBACK_BY_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadUserFeedbackByIdFailure implements Action {
  readonly type = LOAD_USER_FEEDBACK_BY_ID_FAILURE;

  constructor(public payload: string) {}
}

export class AddUserFeedbackHistory implements Action {
  readonly type = ADD_USER_FEEDBACK_HISTORY;

  constructor(public payload: {feedback_id: string, user_id: string, event: string}) {}
}

export class AddUserFeedbackHistorySuccess implements Action {
  readonly type = ADD_USER_FEEDBACK_HISTORY_SUCCESS;

  constructor(public payload: any) {}
}

export class AddUserFeedbackHistoryFailure implements Action {
  readonly type = ADD_USER_FEEDBACK_HISTORY_FAILURE;

  constructor(public payload: string) {}
}

export class LoadUserFeedbackHistory implements Action {
  readonly type = LOAD_USER_FEEDBACK_HISTORY;

  constructor(public payload: string) {}
}

export class LoadUserFeedbackHistorySuccess implements Action {
  readonly type = LOAD_USER_FEEDBACK_HISTORY_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadUserFeedbackHistoryFailure implements Action {
  readonly type = LOAD_USER_FEEDBACK_HISTORY_FAILURE;

  constructor(public payload: string) {}
}

export class UpdateUserFeedback implements Action {
  readonly type = UPDATE_USER_FEEDBACK;

  constructor(public payload: {feedback_id: string, status?: string, assigned_to?: string}) {}
}

export class UpdateUserFeedbackSuccess implements Action {
  readonly type = UPDATE_USER_FEEDBACK_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateUserFeedbackFailure implements Action {
  readonly type = UPDATE_USER_FEEDBACK_FAILURE;

  constructor(public payload: string) {}
}

export class LoadUserFeedbackEvents implements Action {
  readonly type = LOAD_USER_FEEDBACK_EVENTS;

  constructor(public payload?: any) {}
}

export class LoadUserFeedbackEventsSuccess implements Action {
  readonly type = LOAD_USER_FEEDBACK_EVENTS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadUserFeedbackEventsFailure implements Action {
  readonly type = LOAD_USER_FEEDBACK_EVENTS_FAILURE;

  constructor(public payload: string) {}
}

export type All = LoadUserFeedback | LoadUserFeedbackSuccess | LoadUserFeedbackFailure | LoadUserFeedbackById |
  LoadUserFeedbackByIdSuccess | LoadUserFeedbackByIdFailure | AddUserFeedbackHistory | AddUserFeedbackHistorySuccess |
  AddUserFeedbackHistoryFailure | LoadUserFeedbackHistory | LoadUserFeedbackHistorySuccess | LoadUserFeedbackHistoryFailure |
  UpdateUserFeedback | UpdateUserFeedbackSuccess | UpdateUserFeedbackFailure | LoadUserFeedbackEvents | LoadUserFeedbackEventsSuccess |
  LoadUserFeedbackEventsFailure;

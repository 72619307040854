import * as RelationActions from './relation.actions';

type Action = RelationActions.All;

export interface RelationState {
  loading: boolean;
  entitiesByUser: any;
  error: string;
}

const initialState = {
  loading: false,
  entitiesByUser: false,
  error: null,
};

export function reducer(state: RelationState = initialState, {type, payload}: Action): RelationState {
  switch (type) {
    case RelationActions.LOAD_RELATIONS_BY_USER:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case RelationActions.LOAD_RELATIONS_BY_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesByUser: Object.assign({}, state.entitiesByUser, {
          [payload.user_id]: payload.results
        })
      });
    case RelationActions.LOAD_RELATIONS_BY_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case RelationActions.REMOVE_RELATION:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case RelationActions.REMOVE_RELATION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesByUser: Object.assign({}, state.entitiesByUser, {
          [payload.user_id]: {
            outgoing: state.entitiesByUser[payload.user_id].outgoing.filter(relation => relation.id !== payload.relation_id),
            incoming: state.entitiesByUser[payload.user_id].incoming.filter(relation => relation.id !== payload.relation_id)
          }
        })
      });
    case RelationActions.REMOVE_RELATION_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case RelationActions.RELATION_CREATED:
      return Object.assign({}, state, {
        loading: false,
        entitiesByUser: Object.assign({}, state.entitiesByUser, {
          [payload.grantor_id]: {
            outgoing: [...state.entitiesByUser[payload.grantor_id].outgoing, payload],
            incoming: state.entitiesByUser[payload.grantor_id].incoming,
          }
        })
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: RelationState) => state.loading;
export const getEntitiesByUser = (state: RelationState) => state.entitiesByUser;
export const getError = (state: RelationState) => state.error;

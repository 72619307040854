import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {UserService} from '../../core/user.service';
import {Action} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import * as CrudActions from './crud.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {InstitutionService} from '../../core/institution.service';
import {ToastError, ToastSuccess} from '../toast/toast.actions';
import * as InstitutionActions from '../institution/institution.actions';
import * as UserActions from '../user/user.actions';
import * as GroupActions from '../group/group.actions';
import {GroupService} from '../../core/group.service';
import {SearchUser} from '../user/user.actions';
import {MunicipalityService} from '../../core/municipalities.service';

@Injectable()
export class CrudEffects {

  @Effect() createUser$: Observable<Action> = this._actions$.pipe(
    ofType(CrudActions.CREATE_USER),
    map((action: CrudActions.CreateUser) => action.payload),
    switchMap((payload) => {
      return this._user.createUser(payload)
        .pipe(
          map(data => new CrudActions.CreateUserSuccess(data)),
          catchError(error => of(new CrudActions.CreateUserFailure(error)))
        );
    }),
  );

  @Effect() createdUser$: Observable<Action> = this._actions$.pipe(
    ofType(CrudActions.CREATE_USER_SUCCESS),
    map((_) => new ToastSuccess('User created!')),
  );

  @Effect() createdUserFailure$: Observable<Action> = this._actions$.pipe(
    ofType(CrudActions.CREATE_USER_FAILURE),
    map((response: any) => {
      if (response.payload.error.message) {
        return new ToastError(response.payload.error.message);
      }

      return new ToastError('Could not create user. Please try again.');
    }),
  );

  @Effect() updatedUser$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.UPDATE_USER_SUCCESS),
    map((_) => new ToastSuccess('User updated!')),
  );

  @Effect() createInstitution$: Observable<Action> = this._actions$.pipe(
    ofType(CrudActions.CREATE_INSTITUTION),
    map((action: CrudActions.CreateInstitution) => action.payload),
    switchMap((payload) => {
      return this._institution.createInstitution(payload.institution, payload.address)
        .pipe(
          map(data => new CrudActions.CreateInstitutionSuccess(data)),
          catchError(error => of(new CrudActions.CreateInstitutionFailure(error)))
        );
    }),
  );

  @Effect() createdInstitution$: Observable<Action> = this._actions$.pipe(
    ofType(CrudActions.CREATE_INSTITUTION_SUCCESS),
    map((_) => new ToastSuccess('Institution created!')),
  );

  @Effect() createdInstitutionFailure$: Observable<Action> = this._actions$.pipe(
    ofType(CrudActions.CREATE_INSTITUTION_FAILURE),
    map((_) => new ToastError('Could not create institution. Please try again.')),
  );

  @Effect() updatedInstitution$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.UPDATE_INSTITUTION_SUCCESS),
    map((_) => new ToastSuccess('Institution updated!')),
  );

  @Effect() createGroup$: Observable<Action> = this._actions$.pipe(
    ofType(CrudActions.CREATE_GROUP),
    map((action: CrudActions.CreateGroup) => action.payload),
    switchMap((payload) => {
      return this._group.createGroup(payload)
        .pipe(
          map(data => new CrudActions.CreateGroupSuccess(data)),
          catchError(error => of(new CrudActions.CreateGroupFailure(error)))
        );
    }),
  );

  @Effect() createdGroup$: Observable<Action> = this._actions$.pipe(
    ofType(CrudActions.CREATE_GROUP_SUCCESS),
    map((_) => new ToastSuccess('Group created!')),
  );

  @Effect() updatedGroup$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.UPDATE_GROUP_SUCCESS),
    map((_) => new ToastSuccess('Group updated!')),
  );

  @Effect() createRelation$: Observable<Action> = this._actions$.pipe(
    ofType(CrudActions.CREATE_RELATION),
    map((action: CrudActions.CreateRelation) => action.payload),
    switchMap((payload) => {
      return this._user.createRelation(payload)
        .pipe(
          map(data => new CrudActions.CreateRelationSuccess(data)),
          catchError(error => of(new CrudActions.CreateRelationFailure(error)))
        );
    }),
  );

  @Effect() createdRelationSuccess$: Observable<Action> = this._actions$.pipe(
    ofType(CrudActions.CREATE_RELATION_SUCCESS),
    map((_) => new ToastSuccess('Relation created!')),
  );

  @Effect() createdRelationFailure$: Observable<Action> = this._actions$.pipe(
    ofType(CrudActions.CREATE_RELATION_FAILURE),
    map((_) => new ToastError('Could not create relation. Please try again.')),
  );

  @Effect() createManager$: Observable<Action> = this._actions$.pipe(
    ofType(CrudActions.CREATE_MANAGER),
    map((action: CrudActions.CreateManager) => action.payload),
    switchMap((payload) => {
      return this._user.createUser(payload)
        .pipe(
          switchMap(data => [
            new CrudActions.CreateManagerSuccess(data),
            new SearchUser(payload.first_name)
          ]),
          catchError(error => of(new CrudActions.CreateManagerFailure(error)))
        );
    }),
  );

  @Effect() createMunicipality$: Observable<Action> = this._actions$.pipe(
    ofType(CrudActions.CREATE_MUNICIPALITY),
    map((action: CrudActions.CreateMunicipality) => action.payload),
    switchMap((payload) => {
      return this._municipality.create(payload)
        .pipe(
          map(data => new CrudActions.CreateMunicipalitySuccess(data)),
          catchError(error => of(new CrudActions.CreateMunicipalityFailure(error)))
        );
    }),
  );

  @Effect() createdMunicipality$: Observable<Action> = this._actions$.pipe(
    ofType(CrudActions.CREATE_MUNICIPALITY_SUCCESS),
    map((_) => new ToastSuccess('Municipality created!')),
  );

  @Effect() createdMunicipalityFailed$: Observable<Action> = this._actions$.pipe(
    ofType(CrudActions.CREATE_MUNICIPALITY_FAILURE),
    map((response: any) => {
      let error = 'Unable to create municipality!';

      if (response && response.payload) {
        error = response.payload.error.message;
      }

      return new ToastError(error);
    }),
  );

  constructor(private _actions$: Actions, private _user: UserService, private _institution: InstitutionService,
              private _group: GroupService, private _municipality: MunicipalityService) {}
}

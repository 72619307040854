import {Action} from '@ngrx/store';
import {Municipality} from '../../interfaces';

export const LOAD_MUNICIPALITIES  = 'LOAD_MUNICIPALITIES';
export const LOAD_MUNICIPALITIES_SUCCESS  = 'LOAD_MUNICIPALITIES_SUCCESS';
export const LOAD_MUNICIPALITIES_FAILURE  = 'LOAD_MUNICIPALITIES_FAILURE';
export const LOAD_MORE_MUNICIPALITIES  = 'LOAD_MORE_MUNICIPALITIES';
export const LOAD_MORE_MUNICIPALITIES_SUCCESS  = 'LOAD_MORE_MUNICIPALITIES_SUCCESS';
export const LOAD_MORE_MUNICIPALITIES_FAILURE  = 'LOAD_MORE_MUNICIPALITIES_FAILURE';
export const LOAD_MUNICIPALIY_BY_ID  = 'LOAD_MUNICIPALIY_BY_ID';
export const LOAD_MUNICIPALIY_BY_ID_SUCCESS  = 'LOAD_MUNICIPALIY_BY_ID_SUCCESS';
export const LOAD_MUNICIPALIY_BY_ID_FAILURE  = 'LOAD_MUNICIPALIY_BY_ID_FAILURE';
export const UPDATE_MUNICIPALITY  = 'UPDATE_MUNICIPALITY';
export const UPDATE_MUNICIPALITY_SUCCESS  = 'UPDATE_MUNICIPALITY_SUCCESS';
export const UPDATE_MUNICIPALITY_FAILURE  = 'UPDATE_MUNICIPALITY_FAILURE';

export class LoadMunicipalities implements Action {
  readonly type = LOAD_MUNICIPALITIES;

  constructor(public payload: {country: string}) {}
}

export class LoadMunicipalitiesSuccess implements Action {
  readonly type = LOAD_MUNICIPALITIES_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMunicipalitiesFailure implements Action {
  readonly type = LOAD_MUNICIPALITIES_FAILURE;

  constructor(public payload: string) {}
}

export class LoadMoreMunicipalities implements Action {
  readonly type = LOAD_MORE_MUNICIPALITIES;

  constructor(public payload: string) {}
}

export class LoadMoreMunicipalitiesSuccess implements Action {
  readonly type = LOAD_MORE_MUNICIPALITIES_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMoreMunicipalitiesFailure implements Action {
  readonly type = LOAD_MORE_MUNICIPALITIES_FAILURE;

  constructor(public payload: string) {}
}

export class LoadMunicipalityById implements Action {
  readonly type = LOAD_MUNICIPALIY_BY_ID;

  constructor(public payload: string) {}
}

export class LoadMunicipalityByIdSuccess implements Action {
  readonly type = LOAD_MUNICIPALIY_BY_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMunicipalityByIdFailure implements Action {
  readonly type = LOAD_MUNICIPALIY_BY_ID_FAILURE;

  constructor(public payload: string) {}
}

export class UpdateMunicipality implements Action {
  readonly type = UPDATE_MUNICIPALITY;

  constructor(public payload: Municipality) {}
}

export class UpdateMunicipalitySuccess implements Action {
  readonly type = UPDATE_MUNICIPALITY_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateMunicipalityFailure implements Action {
  readonly type = UPDATE_MUNICIPALITY_FAILURE;

  constructor(public payload: string) {}
}

export type All = LoadMunicipalities | LoadMunicipalitiesSuccess | LoadMunicipalitiesFailure | LoadMoreMunicipalities |
  LoadMoreMunicipalitiesSuccess | LoadMoreMunicipalitiesFailure | LoadMunicipalityById | LoadMunicipalityByIdSuccess |
  LoadMunicipalityByIdFailure | UpdateMunicipality | UpdateMunicipalitySuccess | UpdateMunicipalityFailure;

import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {forkJoin, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Address, Institution, SelectedEntity} from '../interfaces';

@Injectable()
export class InstitutionService extends ApiService {

  constructor(private _http: HttpClient, protected _ls: LocalStorage) {
    super(_ls);
  }

  load(): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}institutions`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
      );
  }

  loadMore(url: string): Observable<any> {
    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
      );
  }

  loadById(institutionId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institutionId}`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers}))
      );
  }

  createInstitution(institution: Institution, address: Address): Observable<any> {
    let headers: HttpHeaders;

    if (!institution.address_id || institution.address_id === null) {
      return this._getAuthHeaders()
        .pipe(
          switchMap((h: HttpHeaders) => {
            headers = h;
            const createAddressUrl = `${environment['API_URL']}${environment['API_VERSION']}addresses`;
            return this._http.post(createAddressUrl, address, {headers: headers});
          }),
          switchMap((a: any) => {
            const createInstitutionUrl = `${environment['API_URL']}${environment['API_VERSION']}institutions`;
            institution = Object.assign({}, institution, {address_id: a.id});
            return this._http.post(createInstitutionUrl, institution, {headers: headers});
          })
        );
    } else {
      return this._getAuthHeaders()
        .pipe(
          switchMap((h: HttpHeaders) => {
            const updateInstitutionUrl = `${environment['API_URL']}${environment['API_VERSION']}institutions`;
            return this._http.post(updateInstitutionUrl, institution, {headers: h});
          })
        );
    }
  }

  searchInstitutions(name: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}institutions/search?name=${name}`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
      );
  }

  updateInstitution(institution: Institution, address: Address): Observable<any> {
    let headers: HttpHeaders;

    if (address.street !== null && !address.id) {
      return this._getAuthHeaders()
        .pipe(
          switchMap((h: HttpHeaders) => {
            headers = h;
            const createAddressUrl = `${environment['API_URL']}${environment['API_VERSION']}addresses`;
            return this._http.post(createAddressUrl, address, {headers: headers});
          }),
          switchMap((a: any) => {
            const updateInstitutionUrl = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institution.id}`;
            institution = Object.assign({}, institution, {address_id: a.id});
            return this._http.patch(updateInstitutionUrl, institution, {headers: headers});
          })
        );
    } else {
      return this._getAuthHeaders()
        .pipe(
          switchMap((h: HttpHeaders) => {
            const updateInstitutionUrl = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institution.id}`;
            return this._http.patch(updateInstitutionUrl, institution, {headers: h});
          })
        );
    }
  }

  loadServiceReceivers(institutionId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institutionId}/service-receivers`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
        map((result: any) => ({institution_id: institutionId, serviceReceivers: result.results}))
      );
  }

  addServiceReceivers(institutionId: string, userIds: string[]): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institutionId}/service-receivers`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.post(url, {user_ids: userIds}, {headers: headers})),
        map((result: any) => ({institution_id: institutionId, serviceReceivers: result.results}))
      );
  }

  removeServiceReceiver(institutionId: string, userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institutionId}/service-receivers/${userId}`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.delete(url, {headers: headers})),
        map((result: any) => ({institution_id: institutionId, serviceReceivers: result.results}))
      );
  }

  loadEmployees(institutionId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institutionId}/employees`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
        map((result: any) => ({institution_id: institutionId, employees: result.results}))
      );
  }

  addEmployees(institutionId: string, userIds: string[]): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institutionId}/employees`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.post(url, {user_ids: userIds}, {headers: headers})),
        map((result: any) => ({institution_id: institutionId, employees: result.results}))
      );
  }

  removeEmployee(institutionId: string, userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institutionId}/employees/${userId}`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.delete(url, {headers: headers})),
        map((result: any) => ({institution_id: institutionId, employees: result.results}))
      );
  }

  loadUserInstitutions(userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users/${userId}/institutions`;
    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
        map(institutions => Object.assign({}, {
          user_id: userId,
          institutions: institutions
        }))
      );
  }

  addUserToInstitutions(userId: string, selected: SelectedEntity[]): Observable<any> {
    const observables = selected.map((s: SelectedEntity) => {
      if (s.relation_type === 'MEMBER') {
        return this.addServiceReceivers(s.id, [userId]);
      } else if (s.relation_type === 'EMPLOYED_AT') {
        return this.addEmployees(s.id, [userId]);
      }
    });

    return forkJoin(observables);
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as rootState from '../../store';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {Toast} from '../../store/toast/toast.reducer';
import {ToastClear} from '../../store/toast/toast.actions';

@Component({
  selector: 'app-toast',
  styleUrls: ['./toast.scss'],
  template: `
    <div class="toast" [ngClass]="{
      'toast--success': toast.type === 'ToastSuccess',
      'toast--error': toast.type === 'ToastError',
      'toast--show': toast.message !== null
    }">
      {{ toast.message }}
    </div>
  `
})
export class ToastComponent implements OnInit, OnDestroy {
  toast: Toast;

  private _subscription: Subscription;

  constructor(private _store: Store<rootState.IAppState>) {}

  ngOnInit(): void {
    this._subscription = this._store.select(rootState.getToast)
      .pipe(
        map((toast) => {
          this.toast = toast;

          if (this.toast && this.toast.message !== null) {
            setTimeout(() => {
              this._store.dispatch(new ToastClear());
            }, 6000);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}

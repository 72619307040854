import { BrowserModule } from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NavModule} from './nav/nav.module';
import {StoreModule} from '@ngrx/store';
import {reducers} from './store';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from './store/auth/auth.effects';
import {CookieService} from 'ngx-cookie-service';
import {HttpClientModule} from '@angular/common/http';
import {CoreModule} from './core/core.module';
import {StatisticsEffects} from './store/statistics/statistics.effects';
import {UserEffects} from './store/user/user.effects';
import {ToastModule} from './components/toast/toast.module';
import {CrudEffects} from './store/crud/crud.effects';
import {InstitutionEffects} from './store/institution/institution.effects';
import {AddressEffects} from './store/address/address.effects';
import {GroupEffects} from './store/group/group.effects';
import {GlobalErrorHandler} from './global-error-handler';
import {MunicipalityEffects} from './store/municipality/municipality.effects';
import {SupportEffects} from './store/support/support.effects';
import {RelationEffects} from './store/relation/relation.effects';
import {ModalModule} from './components/modal/modal.module';
import {EulaEffects} from './store/eula/eula.effects';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    NavModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([
      AuthEffects,
      StatisticsEffects,
      UserEffects,
      CrudEffects,
      InstitutionEffects,
      AddressEffects,
      GroupEffects,
      MunicipalityEffects,
      SupportEffects,
      RelationEffects,
      EulaEffects
    ]),
    ToastModule,
    ModalModule
  ],
  providers: [
    CookieService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

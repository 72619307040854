import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmModalComponent} from './confirm-modal.component';
import {ModalService} from './modal.service';
import {AlertModalComponent} from './alert-modal.component';

@NgModule({
  declarations: [
    ConfirmModalComponent,
    AlertModalComponent
  ],
  imports: [
    CommonModule
  ],
  entryComponents: [
    ConfirmModalComponent,
    AlertModalComponent
  ],
  providers: [ModalService]
})
export class ModalModule {}

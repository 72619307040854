import {ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef} from '@angular/core';
import {ConfirmModalComponent} from './confirm-modal.component';
import {AlertModalComponent} from './alert-modal.component';

export interface ModalOptions {
  viewRef: ViewContainerRef;
  title: string;
  text?: string;
  html?: string;
  options?: any[];
  confirmButtonText?: string;
  cancelButtonText: string;
}

export interface AlertModalOptions {
  viewRef: ViewContainerRef;
  title: string;
  text?: string;
}

@Injectable()
export class ModalService {
  confirmComponent: ComponentRef<ConfirmModalComponent>;
  alertComponent: ComponentRef<AlertModalComponent>;

  constructor(private _componentFactoryResolver: ComponentFactoryResolver) {}

  confirm(options: ModalOptions): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this.confirmComponent) {
        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(ConfirmModalComponent);
        this.confirmComponent = componentFactory.create(options.viewRef.injector);

        (<ConfirmModalComponent>this.confirmComponent.instance).title = options.title;
        (<ConfirmModalComponent>this.confirmComponent.instance).text = options.text;
        (<ConfirmModalComponent>this.confirmComponent.instance).html = options.html;
        (<ConfirmModalComponent>this.confirmComponent.instance).confirmButtonText = options.confirmButtonText;
        (<ConfirmModalComponent>this.confirmComponent.instance).cancelButtonText = options.cancelButtonText;

        (<ConfirmModalComponent>this.confirmComponent.instance).onButtonClicked
          .subscribe(result => {
            this.confirmComponent.destroy();
            this.confirmComponent = null;
            return resolve(result);
          });

        options.viewRef.insert(this.confirmComponent.hostView);
      }
    });
  }

  alert(options: AlertModalOptions): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this.alertComponent) {
        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(AlertModalComponent);
        this.alertComponent = componentFactory.create(options.viewRef.injector);

        (<AlertModalComponent>this.alertComponent.instance).title = options.title;
        (<AlertModalComponent>this.alertComponent.instance).text = options.text;

        (<AlertModalComponent>this.alertComponent.instance).onButtonClicked
          .subscribe(result => {
            this.alertComponent.destroy();
            this.alertComponent = null;
            return resolve(result);
          });

        options.viewRef.insert(this.alertComponent.hostView);
      }
    });
  }
}

import {Action} from '@ngrx/store';

export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAILURE = 'LOAD_USERS_FAILURE';
export const LOAD_MORE_USERS = 'LOAD_MORE_USERS';
export const LOAD_MORE_USERS_SUCCESS = 'LOAD_MORE_USERS_SUCCESS';
export const LOAD_MORE_USERS_FAILURE = 'LOAD_MORE_USERS_FAILURE';
export const SEARCH_USER = 'SEARCH_USER';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_FAILURE = 'SEARCH_USER_FAILURE';
export const CLEAR_USER_SEARCH = 'CLEAR_USER_SEARCH';
export const LOAD_USER_BY_ID = 'LOAD_USER_BY_ID';
export const LOAD_USER_BY_ID_SUCCESS = 'LOAD_USER_BY_ID_SUCCESS';
export const LOAD_USER_BY_ID_FAILURE = 'LOAD_USER_BY_ID_FAILURE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';

export class LoadUsers implements Action {
  readonly type = LOAD_USERS;

  constructor(public payload?: {limit: number, offset: number}) {}
}

export class LoadUsersSuccess implements Action {
  readonly type = LOAD_USERS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadUsersFailure implements Action {
  readonly type = LOAD_USERS_FAILURE;

  constructor(public payload: string) {}
}
export class LoadMoreUsers implements Action {
  readonly type = LOAD_MORE_USERS;

  constructor(public payload: string) {}
}

export class LoadMoreUsersSuccess implements Action {
  readonly type = LOAD_MORE_USERS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMoreUsersFailure implements Action {
  readonly type = LOAD_MORE_USERS_FAILURE;

  constructor(public payload: string) {}
}

export class SearchUser implements Action {
  readonly type = SEARCH_USER;

  constructor(public payload: string) {}
}

export class SearchUserSuccess implements Action {
  readonly type = SEARCH_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class SearchUserFailure implements Action {
  readonly type = SEARCH_USER_FAILURE;

  constructor(public payload: string) {}
}

export class ClearUserSearch implements Action {
  readonly type = CLEAR_USER_SEARCH;

  constructor(public payload?: any) {}
}

export class LoadUserById implements Action {
  readonly type = LOAD_USER_BY_ID;

  constructor(public payload: string) {}
}

export class LoadUserByIdSuccess implements Action {
  readonly type = LOAD_USER_BY_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadUserByIdFailure implements Action {
  readonly type = LOAD_USER_BY_ID_FAILURE;

  constructor(public payload: string) {}
}

export class UpdateUser implements Action {
  readonly type = UPDATE_USER;

  constructor(public payload: any) {}
}

export class UpdateUserSuccess implements Action {
  readonly type = UPDATE_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateUserFailure implements Action {
  readonly type = UPDATE_USER_FAILURE;

  constructor(public payload: string) {}
}

export class UpdateUserProfile implements Action {
  readonly type = UPDATE_USER_PROFILE;

  constructor(public payload: {userId: string, userprofile: any}) {}
}

export class UpdateUserProfileSuccess implements Action {
  readonly type = UPDATE_USER_PROFILE_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateUserProfileFailure implements Action {
  readonly type = UPDATE_USER_PROFILE_FAILURE;

  constructor(public payload: string) {}
}

export type All = LoadUsers | LoadUsersSuccess | LoadUsersFailure | LoadMoreUsers | LoadMoreUsersSuccess | LoadMoreUsersFailure |
  SearchUser | SearchUserSuccess | SearchUserFailure | ClearUserSearch | LoadUserById | LoadUserByIdSuccess | LoadUserByIdFailure |
  UpdateUser | UpdateUserSuccess | UpdateUserFailure | UpdateUserProfile | UpdateUserProfileSuccess | UpdateUserProfileFailure;

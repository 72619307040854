import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {switchMap} from 'rxjs/operators';
import {Municipality} from '../interfaces';
import {map} from 'rxjs/internal/operators';

@Injectable()
export class MunicipalityService extends ApiService {
  constructor(private _http: HttpClient, protected _ls: LocalStorage) {
    super(_ls);
  }

  loadByCountry(country: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}municipalities?country=${country}&limit=450&offset=0`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
      );
  }

  loadMoreByCountry(url): Observable<any> {
    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
      );
  }

  loadById(municipalityId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}municipalities/${municipalityId}`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url,{headers: headers})),
      );
  }

  update(municipality: Municipality): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}municipalities/${municipality.id}`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.patch(url, municipality,{headers: headers})),
      );
  }

  create(municipality: Municipality): Observable<Municipality> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}municipalities`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.post(url, municipality, {headers: headers})),
        map((m: Municipality) => m)
      );
  }
}

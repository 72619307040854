import {Action} from '@ngrx/store';
import {Group, Municipality, Relation} from '../../interfaces';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const CREATE_INSTITUTION = 'CREATE_INSTITUTION';
export const CREATE_INSTITUTION_SUCCESS = 'CREATE_INSTITUTION_SUCCESS';
export const CREATE_INSTITUTION_FAILURE = 'CREATE_INSTITUTION_FAILURE';
export const CREATE_GROUP = 'CREATE_GROUP';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';
export const CLEAR_CRUD = 'CLEAR_CRUD';
export const CREATE_RELATION = 'CREATE_RELATION';
export const CREATE_RELATION_SUCCESS = 'CREATE_RELATION_SUCCESS';
export const CREATE_RELATION_FAILURE = 'CREATE_RELATION_FAILURE';
export const CREATE_MANAGER = 'CREATE_MANAGER';
export const CREATE_MANAGER_SUCCESS = 'CREATE_MANAGER_SUCCESS';
export const CREATE_MANAGER_FAILURE = 'CREATE_MANAGER_FAILURE';
export const CREATE_MUNICIPALITY = 'CREATE_MUNICIPALITY';
export const CREATE_MUNICIPALITY_SUCCESS = 'CREATE_MUNICIPALITY_SUCCESS';
export const CREATE_MUNICIPALITY_FAILURE = 'CREATE_MUNICIPALITY_FAILURE';

export class CreateUser implements Action {
  readonly type = CREATE_USER;

  constructor(public payload: any) {}
}

export class CreateUserSuccess implements Action {
  readonly type = CREATE_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateUserFailure implements Action {
  readonly type = CREATE_USER_FAILURE;

  constructor(public payload: string) {}
}

export class CreateInstitution implements Action {
  readonly type = CREATE_INSTITUTION;

  constructor(public payload: {institution: any, address: any}) {}
}

export class CreateInstitutionSuccess implements Action {
  readonly type = CREATE_INSTITUTION_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateInstitutionFailure implements Action {
  readonly type = CREATE_INSTITUTION_FAILURE;

  constructor(public payload: string) {}
}

export class CreateGroup implements Action {
  readonly type = CREATE_GROUP;

  constructor(public payload: Group) {}
}

export class CreateGroupSuccess implements Action {
  readonly type = CREATE_GROUP_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateGroupFailure implements Action {
  readonly type = CREATE_GROUP_FAILURE;

  constructor(public payload: string) {}
}

export class ClearCrud implements Action {
  readonly type = CLEAR_CRUD;

  constructor(public payload?: any) {}
}

export class CreateRelation implements Action {
  readonly type = CREATE_RELATION;

  constructor(public payload: Relation) {}
}

export class CreateRelationSuccess implements Action {
  readonly type = CREATE_RELATION_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateRelationFailure implements Action {
  readonly type = CREATE_RELATION_FAILURE;

  constructor(public payload: string) {}
}

export class CreateManager implements Action {
  readonly type = CREATE_MANAGER;

  constructor(public payload: any) {}
}

export class CreateManagerSuccess implements Action {
  readonly type = CREATE_MANAGER_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateManagerFailure implements Action {
  readonly type = CREATE_MANAGER_FAILURE;

  constructor(public payload: string) {}
}

export class CreateMunicipality implements Action {
  readonly type = CREATE_MUNICIPALITY;

  constructor(public payload: Municipality) {}
}

export class CreateMunicipalitySuccess implements Action {
  readonly type = CREATE_MUNICIPALITY_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateMunicipalityFailure implements Action {
  readonly type = CREATE_MUNICIPALITY_FAILURE;

  constructor(public payload: string) {}
}

export type All = CreateUser | CreateUserSuccess | CreateUserFailure | CreateInstitution | CreateInstitutionSuccess |
  CreateInstitutionFailure | CreateGroup | CreateGroupSuccess | CreateGroupFailure | ClearCrud | CreateRelation | CreateRelationSuccess |
  CreateRelationFailure | CreateManager | CreateManagerSuccess | CreateManagerFailure | CreateMunicipality | CreateMunicipalitySuccess |
  CreateMunicipalityFailure;

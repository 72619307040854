import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {UserService} from '../../core/user.service';
import {Action} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import * as UserActions from './user.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {ToastSuccess} from '../toast/toast.actions';

@Injectable()
export class UserEffects {

  @Effect() loadUsers$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.LOAD_USERS),
    switchMap((_) => {
      return this._service.loadUsers()
        .pipe(
          map(data => new UserActions.LoadUsersSuccess(data)),
          catchError(error => of(new UserActions.LoadUsersFailure(error)))
        );
    }),
  );

  @Effect() loadMoreUsers$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.LOAD_MORE_USERS),
    map((action: UserActions.LoadMoreUsers) => action.payload),
    switchMap((payload) => {
      return this._service.loadMore(payload)
        .pipe(
          map(data => new UserActions.LoadMoreUsersSuccess(data)),
          catchError(error => of(new UserActions.LoadMoreUsersFailure(error)))
        );
    }),
  );

  @Effect() searchUsers$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.SEARCH_USER),
    map((action: UserActions.SearchUser) => action.payload),
    switchMap((payload) => {
      return this._service.searchUsers(payload)
        .pipe(
          map(data => new UserActions.SearchUserSuccess(data)),
          catchError(error => of(new UserActions.SearchUserFailure(error)))
        );
    }),
  );

  @Effect() loadUserById$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.LOAD_USER_BY_ID),
    map((action: UserActions.LoadUserById) => action.payload),
    switchMap((payload) => {
      return this._service.loadUserById(payload)
        .pipe(
          map(data => new UserActions.LoadUserByIdSuccess(data)),
          catchError(error => of(new UserActions.LoadUserByIdFailure(error)))
        );
    }),
  );

  @Effect() updateUser$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.UPDATE_USER),
    map((action: UserActions.UpdateUser) => action.payload),
    switchMap((payload) => {
      return this._service.updateUser(payload)
        .pipe(
          map(data => new UserActions.UpdateUserSuccess(data)),
          map(_ => new ToastSuccess('User was updated!')),
          catchError(error => of(new UserActions.UpdateUserFailure(error)))
        );
    }),
  );

  @Effect() updateUserProfile$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.UPDATE_USER_PROFILE),
    map((action: UserActions.UpdateUserProfile) => action.payload),
    switchMap((payload) => {
      return this._service.updateUserProfile(payload.userId, payload.userprofile)
        .pipe(
          map(data => new UserActions.UpdateUserProfileSuccess(data)),
          map(_ => new ToastSuccess('User was updated!')),
          catchError(error => of(new UserActions.UpdateUserProfileFailure(error)))
        );
    }),
  );

  constructor(private _actions$: Actions, private _service: UserService) {}
}

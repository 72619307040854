import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {GroupService} from '../../core/group.service';
import {Action} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import * as GroupActions from './group.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Group} from '../../interfaces';
import {ToastError, ToastSuccess} from '../toast/toast.actions';
import {LoadGroupsByUser} from './group.actions';

@Injectable()
export class GroupEffects {

  @Effect() loadGroups$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.LOAD_GROUPS),
    switchMap((_) => {
      return this._service.load()
        .pipe(
          map(data => new GroupActions.LoadGroupsSuccess(data)),
          catchError(error => of(new GroupActions.LoadGroupsFailure(error)))
        );
    }),
  );

  @Effect() loadMoreGroups$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.LOAD_MORE_GROUPS),
    map((action: GroupActions.LoadMoreGroups) => action.payload),
    switchMap((payload) => {
      return this._service.loadMore(payload)
        .pipe(
          map(data => new GroupActions.LoadMoreGroupsSuccess(data)),
          catchError(error => of(new GroupActions.LoadMoreGroupsFailure(error)))
        );
    }),
  );

  @Effect() loadGroupById$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.LOAD_GROUP_BY_ID),
    map((action: GroupActions.LoadGroupById) => action.payload),
    switchMap((payload) => {
      return this._service.loadById(payload)
        .pipe(
          map((data: Group) => new GroupActions.LoadGroupByIdSuccess(data)),
          catchError(error => of(new GroupActions.LoadGroupByIdFailure(error)))
        );
    }),
  );

  @Effect() searchGroups$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.SEARCH_GROUP),
    map((action: GroupActions.SearchGroup) => action.payload),
    switchMap((payload) => {
      return this._service.searchGroups(payload)
        .pipe(
          map(data => new GroupActions.SearchGroupSuccess(data)),
          catchError(error => of(new GroupActions.SearchGroupFailure(error)))
        );
    }),
  );

  @Effect() updateGroup$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.UPDATE_GROUP),
    map((action: GroupActions.UpdateGroup) => action.payload),
    switchMap((payload) => {
      return this._service.updateGroup(payload)
        .pipe(
          map(data => new GroupActions.UpdateGroupSuccess(data)),
          catchError(error => of(new GroupActions.UpdateGroupFailure(error)))
        );
    }),
  );

  @Effect() loadGroupAdmins$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.LOAD_GROUP_ADMINS),
    map((action: GroupActions.LoadGroupAdmins) => action.payload),
    switchMap((payload) => {
      return this._service.loadAdmins(payload)
        .pipe(
          map(data => new GroupActions.LoadGroupAdminsSuccess(data)),
          catchError(error => of(new GroupActions.LoadGroupAdminsFailure(error)))
        );
    }),
  );

  @Effect() addAdminsToGroup$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.ADD_GROUP_ADMINS),
    map((action: GroupActions.AddGroupAdmins) => action.payload),
    switchMap((payload) => {
      return this._service.addAdmins(payload.groupId, payload.userIds)
        .pipe(
          map(data => new GroupActions.AddGroupAdminsSuccess(data)),
          catchError(error => of(new GroupActions.AddGroupAdminsFailure(error)))
        );
    }),
  );

  @Effect() addAdminsToGroupSuccess$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.ADD_GROUP_ADMINS_SUCCESS),
    map(_ => new ToastSuccess('Admin was added to group!')),
  );

  @Effect() addAdminsToGroupFailure$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.ADD_GROUP_ADMINS_FAILURE),
    map(_ => new ToastError('Admin was not added to group!')),
  );

  @Effect() removeAdminFromGroup$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.REMOVE_GROUP_ADMIN),
    map((action: GroupActions.RemoveGroupAdmin) => action.payload),
    switchMap((payload) => {
      return this._service.removeAdmin(payload.groupId, payload.userId)
        .pipe(
          map(data => new GroupActions.RemoveGroupAdminSuccess(data)),
          catchError(error => of(new GroupActions.RemoveGroupAdminFailure(error)))
        );
    }),
  );

  @Effect() removeAdminFromGroupSuccess$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.REMOVE_GROUP_ADMIN_SUCCESS),
    map(_ => new ToastSuccess('Admins was removed from group!')),
  );

  @Effect() removeAdminFromGroupFailure$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.REMOVE_GROUP_ADMIN_FAILURE),
    map(_ => new ToastError('Admins was not removed from group!')),
  );

  @Effect() loadGroupMembers$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.LOAD_GROUP_MEMBERS),
    map((action: GroupActions.LoadGroupMembers) => action.payload),
    switchMap((payload) => {
      return this._service.loadMembers(payload)
        .pipe(
          map(data => new GroupActions.LoadGroupMembersSuccess(data)),
          catchError(error => of(new GroupActions.LoadGroupMembersFailure(error)))
        );
    }),
  );

  @Effect() addMembersToGroup$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.ADD_GROUP_MEMBERS),
    map((action: GroupActions.AddGroupMembers) => action.payload),
    switchMap((payload) => {
      return this._service.addMembers(payload.groupId, payload.userIds)
        .pipe(
          map(data => new GroupActions.AddGroupMembersSuccess(data)),
          catchError(error => of(new GroupActions.AddGroupMembersFailure(error)))
        );
    }),
  );

  @Effect() addMembersToGroupSuccess$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.ADD_GROUP_MEMBERS_SUCCESS),
    map(_ => new ToastSuccess('Members was added to group!')),
  );

  @Effect() addMembersToGroupFailure$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.ADD_GROUP_ADMINS_FAILURE),
    map(_ => new ToastError('Members was not added to group!')),
  );

  @Effect() removeMemberFromGroup$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.REMOVE_GROUP_MEMBER),
    map((action: GroupActions.RemoveGroupMember) => action.payload),
    switchMap((payload) => {
      return this._service.removeMember(payload.groupId, payload.userId)
        .pipe(
          switchMap(data => [
            new GroupActions.RemoveGroupMemberSuccess(data),
            new LoadGroupsByUser(payload.userId),
          ]),
          catchError(error => of(new GroupActions.RemoveGroupMemberFailure(error)))
        );
    }),
  );

  @Effect() removeMemberFromGroupSuccess$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.REMOVE_GROUP_MEMBER_SUCCESS),
    map(_ => new ToastSuccess('Members was removed from group!')),
  );

  @Effect() removeMemberFromGroupFailure$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.REMOVE_GROUP_MEMBER_FAILURE),
    map(_ => new ToastError('Members was not removed from group!')),
  );

  @Effect() loadGroupsByUser$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.LOAD_GROUPS_BY_USER),
    map((action: GroupActions.LoadGroupsByUser) => action.payload),
    switchMap((payload) => {
      return this._service.loadUserGroups(payload)
        .pipe(
          map(data => new GroupActions.LoadGroupsByUserSuccess(data)),
          catchError(error => of(new GroupActions.LoadGroupsByUserFailure(error)))
        );
    }),
  );

  @Effect() addUserToGroups$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.ADD_USER_TO_GROUPS),
    map((action: GroupActions.AddUserToGroups) => action.payload),
    switchMap((payload) =>
      this._service.addUserToGroups(payload.userId, payload.selected)
        .pipe(
          switchMap(data => [
            new GroupActions.AddUserToGroupsSuccess(data),
            new LoadGroupsByUser(payload.userId)
          ]),
          catchError(error => of(new GroupActions.AddUserToGroupsFailure(error)))
        )
    ),
  );

  @Effect() addUserToGroupsSuccess$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.ADD_USER_TO_GROUPS_SUCCESS),
    map(_ => new ToastSuccess('User was added to groups!')),
  );

  @Effect() addUserToGroupsFailure$: Observable<Action> = this._actions$.pipe(
    ofType(GroupActions.ADD_USER_TO_GROUPS_FAILURE),
    map(_ => new ToastError('User was not added to groups. Please try again.')),
  );

  constructor(private _actions$: Actions, private _service: GroupService) {}
}

import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import * as rootState from '../../store';
import {Subscription} from 'rxjs';
import {ToggleNavBarMenuCreateDropDown} from '../../store/ui/ui.actions';
import {UnAuth} from '../../store/auth/auth.actions';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild('navbar') navbar: ElementRef;
  showCreateDropDown: boolean;

  private _showCreateDropDownSubscription: Subscription;

  constructor(private _store: Store<rootState.IAppState>) {}

  @HostListener('document:click', ['$event']) click(event) {
    if (!event.target.className.includes('navbar-create-dropdown') && this.showCreateDropDown) {
      this._store.dispatch(new ToggleNavBarMenuCreateDropDown());
    }
  }

  ngOnInit(): void {
    this._showCreateDropDownSubscription = this._store.select(rootState.getUiShowCreateDropDown)
      .subscribe(value => this.showCreateDropDown = value);
  }

  toggleShowCreateDropDown(): void {
    this._store.dispatch(new ToggleNavBarMenuCreateDropDown());
  }

  logout(): void {
    this._store.dispatch(new UnAuth());
  }

  ngOnDestroy(): void {
    if (this._showCreateDropDownSubscription) {
      this._showCreateDropDownSubscription.unsubscribe();
    }
  }
}

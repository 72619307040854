import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class SupportService extends ApiService {

  constructor(private _http: HttpClient, protected _ls: LocalStorage) {
    super(_ls);
  }

  loadUserFeedback(status?: string): Observable<any> {
    let url = `${environment['API_URL']}${environment['API_VERSION']}feedback`;

    if (status !== null && status !== undefined) {
      url += `?status=${status}`;
    }

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
      );
  }

  loadUserFeedbackById(id: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}feedback/${id}`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
      );
  }

  addUserFeedbackHistory(history: any): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}feedback/${history.feedback_id}/history`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.post(url, {event: history.event}, {headers: headers})),
      );
  }

  loadUserFeedbackHistory(feedbackId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}feedback/${feedbackId}/history`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
      );
  }

  updateUserFeedback(feedbackId: string, status?: string, assignedTo?: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}feedback/${feedbackId}`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) =>
          this._http.patch(url, {status: status, assigned_to: assignedTo}, {headers: headers})
        ),
      );
  }

  loadUserFeedbackEvents(): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}feedback/events`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
      );
  }
}

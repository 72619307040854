import * as ToastActions from './toast.actions';
import {ToastType} from '../../enum';

type Action = ToastActions.All;

export interface Toast {
  message: string;
  type: ToastType;
}

export interface ToastState {
  toast: Toast;
}

const initialState: ToastState = {
  toast: {
    message: null,
    type: null
  }
};

export function reducer(state: ToastState = initialState, {type, payload}: Action): ToastState {
  switch (type) {
    case ToastActions.TOAST_SUCCESS:
      return Object.assign({}, state, {
        toast: {
          message: payload,
          type: ToastType.SUCCESS
        }
      });
    case ToastActions.TOAST_ERROR:
      return Object.assign({}, state, {
        toast: {
          message: payload,
          type: ToastType.ERROR
        }
      });
    case ToastActions.TOAST_CLEAR:
      return Object.assign({}, state, {
        toast: {
          message: null,
          type: null
        }
      });
    default:
      return state;
  }
}

export const getToast = (state: ToastState) => state.toast;

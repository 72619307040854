import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class EulaService extends ApiService {
  constructor(private _http: HttpClient, protected _ls: LocalStorage) {
    super(_ls);
  }

  load(): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}eula`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
      );
  }

  loadByVersion(version: number): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}eula/${version}`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
      );
  }

  loadByVersionAndLanguage(version: number, language: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}eula/${version}?language=${language}`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
      );
  }
}

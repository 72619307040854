import {Action} from '@ngrx/store';

export const CHECK_AUTH = 'CHECK_AUTH';
export const CHECK_AUTH_SUCCESS = 'CHECK_AUTH_SUCCESS';
export const CHECK_AUTH_FAILURE = 'CHECK_AUTH_FAILURE';
export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_FAILURE = 'AUTHENTICATE_FAILURE';
export const VERIFY_MFA_TOKEN = 'VERIFY_MFA_TOKEN';
export const VERIFY_MFA_TOKEN_SUCCESS = 'VERIFY_MFA_TOKEN_SUCCESS';
export const VERIFY_MFA_TOKEN_FAILURE = 'VERIFY_MFA_TOKEN_FAILURE';
export const GET_AUTH_USER = 'GET_AUT_USER';
export const GET_AUTH_USER_SUCCESS = 'GET_AUT_USER_SUCCESS';
export const GET_AUTH_USER_FAILURE = 'GET_AUT_USER_FAILURE';
export const UNAUTH = 'UNAUTH';
export const UNAUTH_SUCCESS = 'UNAUTH_SUCCESS';
export const UNAUTH_FAILURE = 'UNAUTH_FAILURE';

export class CheckAuth implements Action {
  readonly type = CHECK_AUTH;

  constructor(public payload?: any) {}
}

export class CheckAuthSuccess implements Action {
  readonly type = CHECK_AUTH_SUCCESS;

  constructor(public payload: any) {}
}

export class CheckAuthFailure implements Action {
  readonly type = CHECK_AUTH_FAILURE;

  constructor(public payload: string) {}
}

export class Authenticate implements Action {
  readonly type = AUTHENTICATE;

  constructor(public payload: {email: string, password: string}) {}
}

export class AuthenticateSuccess implements Action {
  readonly type = AUTHENTICATE_SUCCESS;

  constructor(public payload: any) {}
}

export class AuthenticateFailure implements Action {
  readonly type = AUTHENTICATE_FAILURE;

  constructor(public payload: string) {}
}

export class VerifyMfaToken implements Action {
  readonly type = VERIFY_MFA_TOKEN;

  constructor(public payload: {userId: string, token: string}) {}
}

export class VerifyMfaTokenSuccess implements Action {
  readonly type = VERIFY_MFA_TOKEN_SUCCESS;

  constructor(public payload: any) {}
}

export class VerifyMfaTokenFailure implements Action {
  readonly type = VERIFY_MFA_TOKEN_FAILURE;

  constructor(public payload: string) {}
}

export class GetAuthUser implements Action {
  readonly type = GET_AUTH_USER;

  constructor(public payload?: any) {}
}

export class GetAuthUserSuccess implements Action {
  readonly type = GET_AUTH_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class GetAuthUserFailure implements Action {
  readonly type = GET_AUTH_USER_FAILURE;

  constructor(public payload: string) {}
}

export class UnAuth implements Action {
  readonly type = UNAUTH;

  constructor(public payload?: any) {}
}

export class UnAuthSuccess implements Action {
  readonly type = UNAUTH_SUCCESS;

  constructor(public payload: any) {}
}

export class UnAuthFailure implements Action {
  readonly type = UNAUTH_FAILURE;

  constructor(public payload: string) {}
}

export type All = CheckAuth | CheckAuthSuccess | CheckAuthFailure | Authenticate | AuthenticateSuccess | AuthenticateFailure
  | VerifyMfaToken | VerifyMfaTokenSuccess | VerifyMfaTokenFailure | GetAuthUser | GetAuthUserSuccess | GetAuthUserFailure
  | UnAuth | UnAuthSuccess | UnAuthFailure;

import * as AuthActions from './auth.actions';
import {AuthenticationState} from '../../enum';

type Action = AuthActions.All;

export interface AuthState {
  loading: boolean;
  authUser: any;
  state: AuthenticationState;
  qrCode: string,
  error: string;
  message: string;
}

const initialState: AuthState = {
  loading: false,
  state: null,
  authUser: null,
  qrCode: null,
  error: null,
  message: null
};

export function reducer(state: AuthState = initialState, {type, payload}: Action): AuthState {
  switch (type) {
    case AuthActions.CHECK_AUTH:
      return Object.assign({}, state, {
        loading: true
      });
    case AuthActions.CHECK_AUTH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        state: payload
      });
    case AuthActions.CHECK_AUTH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    case AuthActions.AUTHENTICATE:
      return Object.assign({}, state, {
        loading: true,
        state: AuthenticationState.AUTHENTICATING,
        error: null
      });
    case AuthActions.AUTHENTICATE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        state: (payload.qr !== null) ? AuthenticationState.ADD_MFA : AuthenticationState.VERIFY_MFA_TOKEN,
        authUser: payload.user,
        qrCode: payload.qr
      });
    case AuthActions.AUTHENTICATE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        state: AuthenticationState.UNAUTHENTICATED,
        error: payload.error
      });
    case AuthActions.VERIFY_MFA_TOKEN:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        state: AuthenticationState.AUTHENTICATING
      });
    case AuthActions.VERIFY_MFA_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        state: AuthenticationState.AUTHENTICATED
      });
    case AuthActions.VERIFY_MFA_TOKEN_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        state: AuthenticationState.VERIFY_MFA_TOKEN,
        error: payload.error
      });
    case AuthActions.GET_AUTH_USER:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
    case AuthActions.GET_AUTH_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        authUser: payload
      });
    case AuthActions.GET_AUTH_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case AuthActions.UNAUTH:
      return Object.assign({}, state, {
        loading: true,
        authUser: null
      });
    case AuthActions.UNAUTH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        state: AuthenticationState.UNAUTHENTICATED
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: AuthState) => state.loading;
export const getAuthenticationState = (state: AuthState) => state.state;
export const getAuthUser = (state: AuthState) => state.authUser;
export const getError = (state: AuthState) => state.error;
export const getQrCode = (state: AuthState) => state.qrCode;

import * as CrudActions from './crud.actions';
import {CrudOpState} from '../../enum';
import {handleError} from '../helpers';

type Action = CrudActions.All;

export interface CrudState {
  loading: boolean;
  crudState: CrudOpState;
  entity: any;
}

const initialState: CrudState = {
  loading: false,
  crudState: null,
  entity: null
};

export function reducer(state: CrudState = initialState, {type, payload}: Action): CrudState {
  switch (type) {
    case CrudActions.CREATE_USER:
      return Object.assign({}, state, {
        loading: true,
        crudState: null,
        error: null
      });
    case CrudActions.CREATE_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entity: payload,
        crudState: CrudOpState.USER_CREATED,
        error: null
      });
    case CrudActions.CREATE_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case CrudActions.CREATE_INSTITUTION:
      return Object.assign({}, state, {
        loading: true,
        crudState: null,
        error: null
      });
    case CrudActions.CREATE_INSTITUTION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entity: payload,
        crudState: CrudOpState.INSTITUTION_CREATED,
        error: null
      });
    case CrudActions.CREATE_INSTITUTION_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case CrudActions.CREATE_GROUP:
      return Object.assign({}, state, {
        loading: true,
        crudState: null,
        error: null
      });
    case CrudActions.CREATE_GROUP_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entity: payload,
        crudState: CrudOpState.GROUP_CREATED,
        error: null
      });
    case CrudActions.CREATE_GROUP_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case CrudActions.CLEAR_CRUD:
      return Object.assign({}, state, {
        loading: false,
        entity: null,
        crudState: null,
        error: null
      });
    case CrudActions.CREATE_MANAGER:
      return Object.assign({}, state, {
        loading: true,
        crudState: CrudOpState.CREATING_MANAGER,
        error: null
      });
    case CrudActions.CREATE_MANAGER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entity: payload,
        crudState: CrudOpState.MANAGER_CREATED,
        error: null
      });
    case CrudActions.CREATE_MANAGER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case CrudActions.CREATE_MUNICIPALITY:
      return Object.assign({}, state, {
        loading: true,
        crudState: CrudOpState.CREATING,
        error: null
      });
    case CrudActions.CREATE_MUNICIPALITY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        crudState: CrudOpState.MUNICIPALITY_CREATED,
        error: null
      });
    case CrudActions.CREATE_MUNICIPALITY_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        crudState: null,
        error: handleError(payload)
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: CrudState) => state.loading;
export const getEntity = (state: CrudState) => state.entity;
export const getState = (state: CrudState) => state.crudState;

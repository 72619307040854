import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable()
export class StatisticsService extends ApiService {

  constructor(private _http: HttpClient, protected _ls: LocalStorage) {
    super(_ls);
  }

  getUserStatistics(): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}stats/users`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers}))
      );
  }

  getInstitutionStatistics(): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}stats/institutions`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers}))
      );
  }

  loadServiceReceiverList(): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}stats/service-receiver-list`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers}))
      );
  }
}

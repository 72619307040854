import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as RelationActions from './relation.actions';
import {UserService} from '../../core/user.service';
import {ToastError, ToastSuccess} from '../toast/toast.actions';
import * as CreateActions from '../crud/crud.actions';

@Injectable()
export class RelationEffects {
  @Effect() userFeedback$: Observable<Action> = this.actions$.pipe(
    ofType(RelationActions.LOAD_RELATIONS_BY_USER),
    map((action: RelationActions.LoadRelationsByUser) => action.payload),
    switchMap(payload => {
      return this._service.loadUserRelations(payload)
        .pipe(
          map(state => new RelationActions.LoadRelationsByUserSuccess(state)),
          catchError(error => of(new RelationActions.LoadRelationsByUserFailure(error)))
        );
    })
  );

  @Effect() removeRelation$: Observable<Action> = this.actions$.pipe(
    ofType(RelationActions.REMOVE_RELATION),
    map((action: RelationActions.RemoveRelation) => action.payload),
    switchMap(payload => {
      return this._service.removeRelation(payload.relationId, payload.userId)
        .pipe(
          map(state => new RelationActions.RemoveRelationSuccess(state)),
          catchError(error => of(new RelationActions.RemoveRelationFailure(error)))
        );
    })
  );

  @Effect() removeRelationSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(RelationActions.REMOVE_RELATION_SUCCESS),
    map(_ => new ToastSuccess('Relation was removed!')),
  );

  @Effect() removeRelationFailure$: Observable<Action> = this.actions$.pipe(
    ofType(RelationActions.REMOVE_RELATION_FAILURE),
    map(_ => new ToastError('Unable to remove relation!')),
  );

  @Effect() createRelationSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(CreateActions.CREATE_RELATION_SUCCESS),
    map((action: CreateActions.CreateRelationSuccess) => action.payload),
    map(payload => new RelationActions.RelationCreated(payload)),
  );

  constructor(private actions$: Actions, private _service: UserService) {}
}

import {Action} from '@ngrx/store';

export const LOAD_EULA = 'LOAD_EULA';
export const LOAD_EULA_SUCCESS = 'LOAD_EULA_SUCCESS';
export const LOAD_EULA_FAILURE = 'LOAD_EULA_FAILURE';
export const LOAD_EULA_BY_VERSION = 'LOAD_EULA_BY_VERSION';
export const LOAD_EULA_BY_VERSION_SUCCESS = 'LOAD_EULA_BY_VERSION_SUCCESS';
export const LOAD_EULA_BY_VERSION_FAILURE = 'LOAD_EULA_BY_VERSION_FAILURE';
export const LOAD_EULA_BY_VERSION_AND_LANGUAGE = 'LOAD_EULA_BY_VERSION_AND_LANGUAGE';
export const LOAD_EULA_BY_VERSION_AND_LANGUAGE_SUCCESS = 'LOAD_EULA_BY_VERSION_AND_LANGUAGE_SUCCESS';
export const LOAD_EULA_BY_VERSION_AND_LANGUAGE_FAILURE = 'LOAD_EULA_BY_VERSION_AND_LANGUAGE_FAILURE';

export class LoadEula implements Action {
  readonly type = LOAD_EULA;

  constructor(public payload?: any) {}
}

export class LoadEulaSuccess implements Action {
  readonly type = LOAD_EULA_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadEulaFailure implements Action {
  readonly type = LOAD_EULA_FAILURE;

  constructor(public payload: string) {}
}

export class LoadEulaByVersion implements Action {
  readonly type = LOAD_EULA_BY_VERSION;

  constructor(public payload: number) {}
}

export class LoadEulaByVersionSuccess implements Action {
  readonly type = LOAD_EULA_BY_VERSION_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadEulaByVersionFailure implements Action {
  readonly type = LOAD_EULA_BY_VERSION_FAILURE;

  constructor(public payload: string) {}
}

export class LoadEulaByVersionAndLanguage implements Action {
  readonly type = LOAD_EULA_BY_VERSION_AND_LANGUAGE;

  constructor(public payload: {version_number: number, language: string}) {}
}

export class LoadEulaByVersionAndLanguageSuccess implements Action {
  readonly type = LOAD_EULA_BY_VERSION_AND_LANGUAGE_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadEulaByVersionAndLanguageFailure implements Action {
  readonly type = LOAD_EULA_BY_VERSION_AND_LANGUAGE_FAILURE;

  constructor(public payload: string) {}
}

export type All = LoadEula | LoadEulaSuccess | LoadEulaFailure | LoadEulaByVersion | LoadEulaByVersionSuccess | LoadEulaByVersionFailure |
  LoadEulaByVersionAndLanguage | LoadEulaByVersionAndLanguageSuccess | LoadEulaByVersionAndLanguageFailure;

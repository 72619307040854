import {Action} from '@ngrx/store';

export const TOAST_SUCCESS = 'TOAST_SUCCESS';
export const TOAST_ERROR = 'TOAST_ERROR';
export const TOAST_CLEAR = 'TOAST_CLEAR';

export class ToastSuccess implements Action {
  readonly type = TOAST_SUCCESS;

  constructor(public payload: string) {}
}

export class ToastError implements Action {
  readonly type = TOAST_ERROR;

  constructor(public payload: string) {}
}

export class ToastClear implements Action {
  readonly type = TOAST_CLEAR;

  constructor(public payload?: any) {}
}

export type All = ToastSuccess | ToastError | ToastClear;

import {Action} from '@ngrx/store';
import {Group, SelectedEntity} from '../../interfaces';

export const LOAD_GROUPS = 'LOAD_GROUPS';
export const LOAD_GROUPS_SUCCESS = 'LOAD_GROUPS_SUCCESS';
export const LOAD_GROUPS_FAILURE = 'LOAD_GROUPS_FAILURE';
export const LOAD_MORE_GROUPS = 'LOAD_MORE_GROUPS';
export const LOAD_MORE_GROUPS_SUCCESS = 'LOAD_MORE_GROUPS_SUCCESS';
export const LOAD_MORE_GROUPS_FAILURE = 'LOAD_MORE_GROUPS_FAILURE';
export const LOAD_GROUP_BY_ID = 'LOAD_GROUP_BY_ID';
export const LOAD_GROUP_BY_ID_SUCCESS = 'LOAD_GROUP_BY_ID_SUCCESS';
export const LOAD_GROUP_BY_ID_FAILURE = 'LOAD_GROUP_BY_ID_FAILURE';
export const SEARCH_GROUP = 'SEARCH_GROUP';
export const SEARCH_GROUP_SUCCESS = 'SEARCH_GROUP_SUCCESS';
export const SEARCH_GROUP_FAILURE = 'SEARCH_GROUP_FAILURE';
export const CLEAR_GROUP_SEARCH = 'CLEAR_GROUP_SEARCH';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';
export const LOAD_GROUP_ADMINS = 'LOAD_GROUP_ADMINS';
export const LOAD_GROUP_ADMINS_SUCCESS = 'LOAD_GROUP_ADMINS_SUCCESS';
export const LOAD_GROUP_ADMINS_FAILURE = 'LOAD_GROUP_ADMINS_FAILURE';
export const ADD_GROUP_ADMINS = 'ADD_GROUP_ADMINS';
export const ADD_GROUP_ADMINS_SUCCESS = 'ADD_GROUP_ADMINS_SUCCESS';
export const ADD_GROUP_ADMINS_FAILURE = 'ADD_GROUP_ADMINS_FAILURE';
export const REMOVE_GROUP_ADMIN = 'REMOVE_GROUP_ADMIN';
export const REMOVE_GROUP_ADMIN_SUCCESS = 'REMOVE_GROUP_ADMIN_SUCCESS';
export const REMOVE_GROUP_ADMIN_FAILURE = 'REMOVE_GROUP_ADMIN_FAILURE';
export const LOAD_GROUP_MEMBERS = 'LOAD_GROUP_MEMBERS';
export const LOAD_GROUP_MEMBERS_SUCCESS = 'LOAD_GROUP_MEMBERS_SUCCESS';
export const LOAD_GROUP_MEMBERS_FAILURE = 'LOAD_GROUP_MEMBERS_FAILURE';
export const ADD_GROUP_MEMBERS = 'ADD_GROUP_MEMBERS';
export const ADD_GROUP_MEMBERS_SUCCESS = 'ADD_GROUP_MEMBERS_SUCCESS';
export const ADD_GROUP_MEMBERS_FAILURE = 'ADD_GROUP_MEMBERS_FAILURE';
export const REMOVE_GROUP_MEMBER = 'REMOVE_GROUP_MEMBER';
export const REMOVE_GROUP_MEMBER_SUCCESS = 'REMOVE_GROUP_MEMBER_SUCCESS';
export const REMOVE_GROUP_MEMBER_FAILURE = 'REMOVE_GROUP_MEMBER_FAILURE';
export const LOAD_GROUPS_BY_USER = 'LOAD_GROUPS_BY_USER';
export const LOAD_GROUPS_BY_USER_SUCCESS = 'LOAD_GROUPS_BY_USER_SUCCESS';
export const LOAD_GROUPS_BY_USER_FAILURE = 'LOAD_GROUPS_BY_USER_FAILURE';
export const ADD_USER_TO_GROUPS = 'ADD_USER_TO_GROUPS';
export const ADD_USER_TO_GROUPS_SUCCESS = 'ADD_USER_TO_GROUPS_SUCCESS';
export const ADD_USER_TO_GROUPS_FAILURE = 'ADD_USER_TO_GROUPS_FAILURE';


export class LoadGroups implements Action {
  readonly type = LOAD_GROUPS;

  constructor(public payload?: any) {}
}

export class LoadGroupsSuccess implements Action {
  readonly type = LOAD_GROUPS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadGroupsFailure implements Action {
  readonly type = LOAD_GROUPS_FAILURE;

  constructor(public payload: string) {}
}

export class LoadMoreGroups implements Action {
  readonly type = LOAD_MORE_GROUPS;

  constructor(public payload: string) {}
}

export class LoadMoreGroupsSuccess implements Action {
  readonly type = LOAD_MORE_GROUPS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMoreGroupsFailure implements Action {
  readonly type = LOAD_MORE_GROUPS_FAILURE;

  constructor(public payload: string) {}
}

export class LoadGroupById implements Action {
  readonly type = LOAD_GROUP_BY_ID;

  constructor(public payload: string) {}
}

export class LoadGroupByIdSuccess implements Action {
  readonly type = LOAD_GROUP_BY_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadGroupByIdFailure implements Action {
  readonly type = LOAD_GROUP_BY_ID_FAILURE;

  constructor(public payload: string) {}
}

export class SearchGroup implements Action {
  readonly type = SEARCH_GROUP;

  constructor(public payload: string) {}
}

export class SearchGroupSuccess implements Action {
  readonly type = SEARCH_GROUP_SUCCESS;

  constructor(public payload: any) {}
}

export class SearchGroupFailure implements Action {
  readonly type = SEARCH_GROUP_FAILURE;

  constructor(public payload: string) {}
}

export class ClearGroupSearch implements Action {
  readonly type = CLEAR_GROUP_SEARCH;

  constructor(public payload?: any) {}
}

export class UpdateGroup implements Action {
  readonly type = UPDATE_GROUP;

  constructor(public payload: Group) {}
}

export class UpdateGroupSuccess implements Action {
  readonly type = UPDATE_GROUP_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateGroupFailure implements Action {
  readonly type = UPDATE_GROUP_FAILURE;

  constructor(public payload: string) {}
}

export class LoadGroupAdmins implements Action {
  readonly type = LOAD_GROUP_ADMINS;

  constructor(public payload: string) {}
}

export class LoadGroupAdminsSuccess implements Action {
  readonly type = LOAD_GROUP_ADMINS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadGroupAdminsFailure implements Action {
  readonly type = LOAD_GROUP_ADMINS_FAILURE;

  constructor(public payload: string) {}
}

export class AddGroupAdmins implements Action {
  readonly type = ADD_GROUP_ADMINS;

  constructor(public payload: {groupId: string, userIds: string[]}) {}
}

export class AddGroupAdminsSuccess implements Action {
  readonly type = ADD_GROUP_ADMINS_SUCCESS;

  constructor(public payload: any) {}
}

export class AddGroupAdminsFailure implements Action {
  readonly type = ADD_GROUP_ADMINS_FAILURE;

  constructor(public payload: string) {}
}

export class RemoveGroupAdmin implements Action {
  readonly type = REMOVE_GROUP_ADMIN;

  constructor(public payload: {groupId: string, userId: string}) {}
}

export class RemoveGroupAdminSuccess implements Action {
  readonly type = REMOVE_GROUP_ADMIN_SUCCESS;

  constructor(public payload: any) {}
}

export class RemoveGroupAdminFailure implements Action {
  readonly type = REMOVE_GROUP_ADMIN_FAILURE;

  constructor(public payload: string) {}
}

export class LoadGroupMembers implements Action {
  readonly type = LOAD_GROUP_MEMBERS;

  constructor(public payload: string) {}
}

export class LoadGroupMembersSuccess implements Action {
  readonly type = LOAD_GROUP_MEMBERS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadGroupMembersFailure implements Action {
  readonly type = LOAD_GROUP_MEMBERS_FAILURE;

  constructor(public payload: string) {}
}

export class AddGroupMembers implements Action {
  readonly type = ADD_GROUP_MEMBERS;

  constructor(public payload: {groupId: string, userIds: string[]}) {}
}

export class AddGroupMembersSuccess implements Action {
  readonly type = ADD_GROUP_MEMBERS_SUCCESS;

  constructor(public payload: any) {}
}

export class AddGroupMembersFailure implements Action {
  readonly type = ADD_GROUP_MEMBERS_FAILURE;

  constructor(public payload: string) {}
}

export class RemoveGroupMember implements Action {
  readonly type = REMOVE_GROUP_MEMBER;

  constructor(public payload: {groupId: string, userId: string}) {}
}

export class RemoveGroupMemberSuccess implements Action {
  readonly type = REMOVE_GROUP_MEMBER_SUCCESS;

  constructor(public payload: any) {}
}

export class RemoveGroupMemberFailure implements Action {
  readonly type = REMOVE_GROUP_MEMBER_FAILURE;

  constructor(public payload: string) {}
}

export class LoadGroupsByUser implements Action {
  readonly type = LOAD_GROUPS_BY_USER;

  constructor(public payload: string) {}
}

export class LoadGroupsByUserSuccess implements Action {
  readonly type = LOAD_GROUPS_BY_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadGroupsByUserFailure implements Action {
  readonly type = LOAD_GROUPS_BY_USER_FAILURE;

  constructor(public payload: string) {}
}

export class AddUserToGroups implements Action {
  readonly type = ADD_USER_TO_GROUPS;

  constructor(public payload: {userId: string, selected: SelectedEntity[]}) {}
}

export class AddUserToGroupsSuccess implements Action {
  readonly type = ADD_USER_TO_GROUPS_SUCCESS;

  constructor(public payload: any) {}
}

export class AddUserToGroupsFailure implements Action {
  readonly type = ADD_USER_TO_GROUPS_FAILURE;

  constructor(public payload: string) {}
}

export type All = LoadGroups | LoadGroupsSuccess | LoadGroupsFailure | LoadMoreGroups | LoadMoreGroupsSuccess | LoadMoreGroupsFailure |
  LoadGroupById | LoadGroupByIdSuccess | LoadGroupByIdFailure | SearchGroup | SearchGroupSuccess | SearchGroupFailure | ClearGroupSearch |
  UpdateGroup | UpdateGroupSuccess | UpdateGroupFailure | LoadGroupAdmins | LoadGroupAdminsSuccess | LoadGroupAdminsFailure |
  AddGroupAdmins | AddGroupAdminsSuccess | AddGroupAdminsFailure | RemoveGroupAdmin | RemoveGroupAdminSuccess | RemoveGroupAdminFailure |
  LoadGroupMembers | LoadGroupMembersSuccess | LoadGroupMembersFailure | AddGroupMembers | AddGroupMembersSuccess | AddGroupMembersFailure |
  RemoveGroupMember | RemoveGroupMemberSuccess | RemoveGroupMemberFailure | LoadGroupsByUser | LoadGroupsByUserSuccess |
  LoadGroupsByUserFailure | AddUserToGroups | AddUserToGroupsSuccess | AddUserToGroupsFailure;

import {Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';

@Component({
  template: `
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <section class="modal-card-body">
          <h1 class="title is-3">{{ title }}</h1>
          
          <p>{{ text }}</p>
          
          <div class="columns margin-top--md">
            <div class="column is-4 is-offset-4">
              <button class="button is-danger is-fullwidth" (click)="selectOption(false)">{{ cancelButtonText }}</button>
            </div>
            <div class="column is-4">
              <button class="button is-success is-fullwidth" (click)="selectOption(true)">{{ confirmButtonText }}</button>
            </div>
          </div>
        </section>
      </div>
    </div>
  `
})
export class ConfirmModalComponent {
  @ViewChild('confirmButton') confirmButton: ElementRef;
  @Input() title: string;
  @Input() text: string;
  @Input() html: string;
  @Input() confirmButtonText: string;
  @Input() cancelButtonText: string;

  @Output() onButtonClicked: EventEmitter<boolean> = new EventEmitter();

  selectOption(result: boolean): void {
    this.onButtonClicked.emit(result);
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.code === 'Escape' || event.keyCode === 27) {
      this.onButtonClicked.emit(false);
    }

    if (event.key === 'Enter' || event.code === 'Enter' || event.keyCode === 13) {
      this.onButtonClicked.emit(true);
    }
  }
}

import {Action} from '@ngrx/store';
import {Address, Institution, SelectedEntity} from '../../interfaces';

export const LOAD_INSTITUTIONS = 'LOAD_INSTITUTIONS';
export const LOAD_INSTITUTIONS_SUCCESS = 'LOAD_INSTITUTIONS_SUCCESS';
export const LOAD_INSTITUTIONS_FAILURE = 'LOAD_INSTITUTIONS_FAILURE';
export const LOAD_MORE_INSTITUTIONS = 'LOAD_MORE_INSTITUTIONS';
export const LOAD_MORE_INSTITUTIONS_SUCCESS = 'LOAD_MORE_INSTITUTIONS_SUCCESS';
export const LOAD_MORE_INSTITUTIONS_FAILURE = 'LOAD_MORE_INSTITUTIONS_FAILURE';
export const LOAD_INSTITUTION_BY_ID = 'LOAD_INSTITUTION_BY_ID';
export const LOAD_INSTITUTION_BY_ID_SUCCESS = 'LOAD_INSTITUTION_BY_ID_SUCCESS';
export const LOAD_INSTITUTION_BY_ID_FAILURE = 'LOAD_INSTITUTION_BY_ID_FAILURE';
export const SEARCH_INSTITUTION = 'SEARCH_INSTITUTION';
export const SEARCH_INSTITUTION_SUCCESS = 'SEARCH_INSTITUTION_SUCCESS';
export const SEARCH_INSTITUTION_FAILURE = 'SEARCH_INSTITUTION_FAILURE';
export const CLEAR_INSTITUTION_SEARCH = 'CLEAR_INSTITUTION_SEARCH';
export const UPDATE_INSTITUTION = 'UPDATE_INSTITUTION';
export const UPDATE_INSTITUTION_SUCCESS = 'UPDATE_INSTITUTION_SUCCESS';
export const UPDATE_INSTITUTION_FAILURE = 'UPDATE_INSTITUTION_FAILURE';
export const LOAD_INSTITUTION_SERVICE_RECEIVERS = 'LOAD_INSTITUTION_SERVICE_RECEIVERS';
export const LOAD_INSTITUTION_SERVICE_RECEIVERS_SUCCESS = 'LOAD_INSTITUTION_SERVICE_RECEIVERS_SUCCESS';
export const LOAD_INSTITUTION_SERVICE_RECEIVERS_FAILURE = 'LOAD_INSTITUTION_SERVICE_RECEIVERS_FAILURE';
export const ADD_INSTITUTION_SERVICE_RECEIVERS = 'ADD_INSTITUTION_SERVICE_RECEIVERS';
export const ADD_INSTITUTION_SERVICE_RECEIVERS_SUCCESS = 'ADD_INSTITUTION_SERVICE_RECEIVERS_SUCCESS';
export const ADD_INSTITUTION_SERVICE_RECEIVERS_FAILURE = 'ADD_INSTITUTION_SERVICE_RECEIVERS_FAILURE';
export const REMOVE_INSTITUTION_SERVICE_RECEIVER = 'REMOVE_INSTITUTION_SERVICE_RECEIVER';
export const REMOVE_INSTITUTION_SERVICE_RECEIVER_SUCCESS = 'REMOVE_INSTITUTION_SERVICE_RECEIVER_SUCCESS';
export const REMOVE_INSTITUTION_SERVICE_RECEIVER_FAILURE = 'REMOVE_INSTITUTION_SERVICE_RECEIVER_FAILURE';
export const LOAD_INSTITUTION_EMPLOYEES = 'LOAD_INSTITUTION_EMPLOYEES';
export const LOAD_INSTITUTION_EMPLOYEES_SUCCESS = 'LOAD_INSTITUTION_EMPLOYEES_SUCCESS';
export const LOAD_INSTITUTION_EMPLOYEES_FAILURE = 'LOAD_INSTITUTION_EMPLOYEES_FAILURE';
export const ADD_INSTITUTION_EMPLOYEES = 'ADD_INSTITUTION_EMPLOYEES';
export const ADD_INSTITUTION_EMPLOYEES_SUCCESS = 'ADD_INSTITUTION_EMPLOYEES_SUCCESS';
export const ADD_INSTITUTION_EMPLOYEES_FAILURE = 'ADD_INSTITUTION_EMPLOYEES_FAILURE';
export const REMOVE_INSTITUTION_EMPLOYEE = 'REMOVE_INSTITUTION_EMPLOYEE';
export const REMOVE_INSTITUTION_EMPLOYEE_SUCCESS = 'REMOVE_INSTITUTION_EMPLOYEE_SUCCESS';
export const REMOVE_INSTITUTION_EMPLOYEES_FAILURE = 'REMOVE_INSTITUTION_EMPLOYEES_FAILURE';
export const LOAD_INSTITUTIONS_BY_USER = 'LOAD_INSTITUTIONS_BY_USER';
export const LOAD_INSTITUTIONS_BY_USER_SUCCESS = 'LOAD_INSTITUTIONS_BY_USER_SUCCESS';
export const LOAD_INSTITUTIONS_BY_USER_FAILURE = 'LOAD_INSTITUTIONS_BY_USER_FAILURE';
export const ADD_USER_TO_INSTITUTIONS = 'ADD_USER_TO_INSTITUTIONS';
export const ADD_USER_TO_INSTITUTIONS_SUCCESS = 'ADD_USER_TO_INSTITUTIONS_SUCCESS';
export const ADD_USER_TO_INSTITUTIONS_FAILURE = 'ADD_USER_TO_INSTITUTIONS_FAILURE';

export class LoadInstitutions implements Action {
  readonly type = LOAD_INSTITUTIONS;

  constructor(public payload?: any) {}
}

export class LoadInstitutionsSuccess implements Action {
  readonly type = LOAD_INSTITUTIONS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadInstitutionsFailure implements Action {
  readonly type = LOAD_INSTITUTIONS_FAILURE;

  constructor(public payload: string) {}
}

export class LoadMoreInstitutions implements Action {
  readonly type = LOAD_MORE_INSTITUTIONS;

  constructor(public payload: string) {}
}

export class LoadMoreInstitutionsSuccess implements Action {
  readonly type = LOAD_MORE_INSTITUTIONS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMoreInstitutionsFailure implements Action {
  readonly type = LOAD_MORE_INSTITUTIONS_FAILURE;

  constructor(public payload: string) {}
}

export class LoadInstitutionById implements Action {
  readonly type = LOAD_INSTITUTION_BY_ID;

  constructor(public payload: string) {}
}

export class LoadInstitutionByIdSuccess implements Action {
  readonly type = LOAD_INSTITUTION_BY_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadInstitutionByIdFailure implements Action {
  readonly type = LOAD_INSTITUTION_BY_ID_FAILURE;

  constructor(public payload: string) {}
}

export class SearchInstitution implements Action {
  readonly type = SEARCH_INSTITUTION;

  constructor(public payload: string) {}
}

export class SearchInstitutionSuccess implements Action {
  readonly type = SEARCH_INSTITUTION_SUCCESS;

  constructor(public payload: any) {}
}

export class SearchInstitutionFailure implements Action {
  readonly type = SEARCH_INSTITUTION_FAILURE;

  constructor(public payload: string) {}
}

export class ClearInstitutionSearch implements Action {
  readonly type = CLEAR_INSTITUTION_SEARCH;

  constructor(public payload?: any) {}
}

export class UpdateInstitution implements Action {
  readonly type = UPDATE_INSTITUTION;

  constructor(public payload: {institution: Institution, address: Address}) {}
}

export class UpdateInstitutionSuccess implements Action {
  readonly type = UPDATE_INSTITUTION_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateInstitutionFailure implements Action {
  readonly type = UPDATE_INSTITUTION_FAILURE;

  constructor(public payload: string) {}
}

export class LoadInstitutionServiceReceivers implements Action {
  readonly type = LOAD_INSTITUTION_SERVICE_RECEIVERS;

  constructor(public payload: string) {}
}

export class LoadInstitutionServiceReceiversSuccess implements Action {
  readonly type = LOAD_INSTITUTION_SERVICE_RECEIVERS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadInstitutionServiceReceiversFailure implements Action {
  readonly type = LOAD_INSTITUTION_SERVICE_RECEIVERS_FAILURE;

  constructor(public payload: string) {}
}

export class AddInstitutionServiceReceivers implements Action {
  readonly type = ADD_INSTITUTION_SERVICE_RECEIVERS;

  constructor(public payload: {institutionId: string, userIds: string[]}) {}
}

export class AddInstitutionServiceReceiversSuccess implements Action {
  readonly type = ADD_INSTITUTION_SERVICE_RECEIVERS_SUCCESS;

  constructor(public payload: any) {}
}

export class AddInstitutionServiceReceiversFailure implements Action {
  readonly type = ADD_INSTITUTION_SERVICE_RECEIVERS_FAILURE;

  constructor(public payload: string) {}
}

export class RemoveInstitutionServiceReceiver implements Action {
  readonly type = REMOVE_INSTITUTION_SERVICE_RECEIVER;

  constructor(public payload: {institutionId: string, userId: string}) {}
}

export class RemoveInstitutionServiceReceiverSuccess implements Action {
  readonly type = REMOVE_INSTITUTION_SERVICE_RECEIVER_SUCCESS;

  constructor(public payload: any) {}
}

export class RemoveInstitutionServiceReceiverFailure implements Action {
  readonly type = REMOVE_INSTITUTION_SERVICE_RECEIVER_FAILURE;

  constructor(public payload: string) {}
}

export class LoadInstitutionEmployees implements Action {
  readonly type = LOAD_INSTITUTION_EMPLOYEES;

  constructor(public payload: string) {}
}

export class LoadInstitutionEmployeesSuccess implements Action {
  readonly type = LOAD_INSTITUTION_EMPLOYEES_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadInstitutionEmployeesFailure implements Action {
  readonly type = LOAD_INSTITUTION_EMPLOYEES_FAILURE;

  constructor(public payload: string) {}
}

export class AddInstitutionEmployees implements Action {
  readonly type = ADD_INSTITUTION_EMPLOYEES;

  constructor(public payload: {institutionId: string, userIds: string[]}) {}
}

export class AddInstitutionEmployeesSuccess implements Action {
  readonly type = ADD_INSTITUTION_EMPLOYEES_SUCCESS;

  constructor(public payload: any) {}
}

export class AddInstitutionEmployeesFailure implements Action {
  readonly type = ADD_INSTITUTION_EMPLOYEES_FAILURE;

  constructor(public payload: string) {}
}

export class RemoveInstitutionEmployee implements Action {
  readonly type = REMOVE_INSTITUTION_EMPLOYEE;

  constructor(public payload: {institutionId: string, userId: string}) {}
}

export class RemoveInstitutionEmployeeSuccess implements Action {
  readonly type = REMOVE_INSTITUTION_EMPLOYEE_SUCCESS;

  constructor(public payload: any) {}
}

export class RemoveInstitutionEmployeeFailure implements Action {
  readonly type = REMOVE_INSTITUTION_EMPLOYEES_FAILURE;

  constructor(public payload: string) {}
}

export class LoadInstitutionsByUser implements Action {
  readonly type = LOAD_INSTITUTIONS_BY_USER;

  constructor(public payload: string) {}
}

export class LoadInstitutionsByUserSuccess implements Action {
  readonly type = LOAD_INSTITUTIONS_BY_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadInstitutionsByUserFailure implements Action {
  readonly type = LOAD_INSTITUTIONS_BY_USER_FAILURE;

  constructor(public payload: string) {}
}

export class AddUserToInstitutions implements Action {
  readonly type = ADD_USER_TO_INSTITUTIONS;

  constructor(public payload: {userId: string, selected: SelectedEntity[]}) {}
}

export class AddUserToInstitutionsSuccess implements Action {
  readonly type = ADD_USER_TO_INSTITUTIONS_SUCCESS;

  constructor(public payload: any) {}
}

export class AddUserToInstitutionsFailure implements Action {
  readonly type = ADD_USER_TO_INSTITUTIONS_FAILURE;

  constructor(public payload: string) {}
}

export type All = LoadInstitutions | LoadInstitutionsSuccess | LoadInstitutionsFailure | LoadMoreInstitutions |
  LoadMoreInstitutionsSuccess | LoadMoreInstitutionsFailure | LoadInstitutionById | LoadInstitutionByIdSuccess |
  LoadInstitutionByIdFailure | SearchInstitution | SearchInstitutionSuccess | SearchInstitutionFailure | ClearInstitutionSearch |
  UpdateInstitution | UpdateInstitutionSuccess | UpdateInstitutionFailure | LoadInstitutionServiceReceivers |
  LoadInstitutionServiceReceiversSuccess | LoadInstitutionServiceReceiversFailure | AddInstitutionServiceReceivers |
  AddInstitutionServiceReceiversSuccess | AddInstitutionServiceReceiversFailure | RemoveInstitutionServiceReceiver |
  RemoveInstitutionServiceReceiverSuccess | RemoveInstitutionServiceReceiverFailure | LoadInstitutionEmployees |
  LoadInstitutionEmployeesSuccess | LoadInstitutionEmployeesFailure | AddInstitutionEmployees | AddInstitutionEmployeesSuccess |
  AddInstitutionEmployeesFailure | RemoveInstitutionEmployee | RemoveInstitutionEmployeeSuccess | RemoveInstitutionEmployeeFailure |
  LoadInstitutionsByUser | LoadInstitutionsByUserSuccess | LoadInstitutionsByUserFailure | AddUserToInstitutions |
  AddUserToInstitutionsSuccess | AddUserToInstitutionsFailure;

import * as FormActions from './form.actions';
import {FormType} from '../../enum';

type Action = FormActions.All;

export interface FormState {
  user: string;
  institution: string;
  group: string;
  relation: string;
}

const initialState: FormState = {
  user: null,
  institution: null,
  group: null,
  relation: null,
};

export function reducer(state: FormState = initialState, {type, payload}: Action): FormState {
  switch (type) {
    case FormActions.USER_FORM_ERROR:
      return Object.assign({}, state, {
        user: payload.error
      });
    case FormActions.INSTITUTION_FORM_ERROR:
      return Object.assign({}, state, {
        institution: payload.error
      });
    case FormActions.GROUP_FORM_ERROR:
      return Object.assign({}, state, {
        group: payload.error
      });
    case FormActions.RELATION_FORM_ERROR:
      return Object.assign({}, state, {
        relation: payload
      });
    case FormActions.CLEAR_FORM_ERROR:
      if (payload === FormType.USER_FORM) {
        return Object.assign({}, state, {
          user: null
        });
      } else if (payload === FormType.INSTITUTION_FORM) {
        return Object.assign({}, state, {
          institution: null
        });
      } else if (payload === FormType.GROUP_FORM) {
        return Object.assign({}, state, {
          group: null
        });
      } else if (payload === FormType.RELATION_FORM) {
        return Object.assign({}, state, {
          relation: null
        });
      } else {
        return state;
      }
    default:
      return state;
  }
}

export const getUserError = (state: FormState) => state.user;
export const getInstitutionError = (state: FormState) => state.institution;
export const getGroupError = (state: FormState) => state.group;
export const getRelationError = (state: FormState) => state.relation;

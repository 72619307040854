const ERRORS = {
  UNEXPECTED_ERROR: 'Wooops, something went wrong, but don\'t worry - the error was logged, and our developers are on it.'
};

export function handleError(payload) {
  if (payload.statusText === 'Bad Request' || payload.code <= 500) {
    return ERRORS.UNEXPECTED_ERROR;
  } else {
    return payload.error;
  }
}

export function removeEntity(state: Array<any>, entity: any) {
  const index = state.findIndex(e => entity.id === e.id);

  if (index !== -1) {
    state.splice(index, 1);
    return [...state];
  } else {
    return [...state];
  }
}

export function addEntity(state, entity) {
  const index = state.findIndex(e => entity.id === e.id);

  if (index !== -1) {
    return [...state];
  } else {
    return [...state, entity];
  }
}

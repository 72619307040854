import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as AuthActions from './auth.actions';
import {AuthService} from '../../core/auth.service';

@Injectable()
export class AuthEffects {
  @Effect() checkAuth$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActions.CHECK_AUTH),
    switchMap(_ => {
      return this._service.checkAuthentication()
        .pipe(
          map(state => new AuthActions.CheckAuthSuccess(state)),
          catchError(error => of(new AuthActions.CheckAuthFailure(error)))
        );
    })
  );

  @Effect()
  login$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActions.AUTHENTICATE),
    map((action: AuthActions.Authenticate) => action.payload),
    switchMap((payload: {email: string, password: string}) => {
      return this._service.authenticate(payload)
        .pipe(
          map(authData => new AuthActions.AuthenticateSuccess(authData)),
          catchError(error => of(new AuthActions.AuthenticateFailure(error)))
        );
    }),
  );

  @Effect()
  verifyMfa$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActions.VERIFY_MFA_TOKEN),
    map((action: AuthActions.VerifyMfaToken) => action.payload),
    switchMap((payload: {userId: string, token: string}) => {
      return this._service.verifyMfa(payload.userId, payload.token)
        .pipe(
          map(authData => new AuthActions.VerifyMfaTokenSuccess(authData)),
          catchError(error => of(new AuthActions.VerifyMfaTokenFailure(error)))
        );
    }),
  );

  @Effect()
  getAuthUser$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActions.GET_AUTH_USER),
    switchMap((_: any) => {
      return this._service.getAuthUser()
        .pipe(
          map(authData => new AuthActions.GetAuthUserSuccess(authData)),
          catchError(payload => of(new AuthActions.GetAuthUserFailure(payload)))
        );
    }),
  );

  @Effect()
  userIsUnAuthorized$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActions.GET_AUTH_USER_FAILURE),
    map((_: any) => new AuthActions.UnAuth()),
  );

  @Effect()
  unAuth$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActions.UNAUTH),
    switchMap((_: any) => {
      return this._service.unAuth()
        .pipe(
          map(authData => new AuthActions.UnAuthSuccess(authData)),
          catchError(error => of(new AuthActions.UnAuthFailure(error)))
        );
    }),
  );

  constructor(private actions$: Actions, private _service: AuthService) {}
}

import {NgModule} from '@angular/core';
import {AuthService} from './auth.service';
import {ApiService} from './api.service';
import {StatisticsService} from './statistics.service';
import {UserService} from './user.service';
import {InstitutionService} from './institution.service';
import {AddressService} from './address.service';
import {GroupService} from './group.service';
import {MunicipalityService} from './municipalities.service';
import {SupportService} from './support.service';
import {EulaService} from './eula.service';

@NgModule({
  providers: [
    ApiService,
    AuthService,
    StatisticsService,
    UserService,
    InstitutionService,
    AddressService,
    GroupService,
    MunicipalityService,
    SupportService,
    EulaService
  ]
})
export class CoreModule {}

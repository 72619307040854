import {Action} from '@ngrx/store';

export const TOGGLE_NAV_BAR_MENU_CREATE_DROP_DOWN = 'TOGGLE_NAV_BAR_MENU_CREATE_DROP_DOWN';
export const TOGGLE_SHOW_CREATE_RELATION_FORM = 'TOGGLE_SHOW_CREATE_RELATION_FORM';
export const TOGGLE_SHOW_ADD_USERS_MODAL = 'TOGGLE_SHOW_ADD_USERS_MODAL';
export const TOGGLE_SHOW_ADD_TO_GROUP_MODAL = 'TOGGLE_SHOW_ADD_TO_GROUP_MODAL';
export const TOGGLE_SHOW_ADD_TO_INSTITUTION_MODAL = 'TOGGLE_SHOW_ADD_TO_INSTITUTION_MODAL';
export const TOGGLE_SHOW_CREATE_MANAGER_MODAL = 'TOGGLE_SHOW_CREATE_MANAGER_MODAL';

export class ToggleNavBarMenuCreateDropDown implements Action {
  readonly type = TOGGLE_NAV_BAR_MENU_CREATE_DROP_DOWN;

  constructor(public payload?: any) {}
}

export class ToggleShowCreateRelationForm implements Action {
  readonly type = TOGGLE_SHOW_CREATE_RELATION_FORM;

  constructor(public payload?: any) {}
}

export class ToggleShowAddUsersModal implements Action {
  readonly type = TOGGLE_SHOW_ADD_USERS_MODAL;

  constructor(public payload?: any) {}
}

export class ToggleShowAddToGroupModal implements Action {
  readonly type = TOGGLE_SHOW_ADD_TO_GROUP_MODAL;

  constructor(public payload?: any) {}
}

export class ToggleShowAddToInstitutionModal implements Action {
  readonly type = TOGGLE_SHOW_ADD_TO_INSTITUTION_MODAL;

  constructor(public payload?: any) {}
}

export class ToggleShowCreateManagerModal implements Action {
  readonly type = TOGGLE_SHOW_CREATE_MANAGER_MODAL;

  constructor(public payload?: any) {}
}

export type All = ToggleNavBarMenuCreateDropDown | ToggleShowCreateRelationForm | ToggleShowAddUsersModal | ToggleShowAddToGroupModal |
  ToggleShowAddToInstitutionModal | ToggleShowCreateManagerModal;

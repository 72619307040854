import * as SupportActions from './support.actions';

type Action = SupportActions.All;

export interface SupportState {
  loading: boolean;
  updating: boolean;
  feedback: any;
  feedbackById: any;
  historyById: any;
  error: string;
  events: any[];
}

const initialState = {
  loading: false,
  updating: false,
  feedback: null,
  feedbackById: null,
  historyById: null,
  error: null,
  events: null
};

export function reducer(state: SupportState = initialState, {type, payload}: Action): SupportState {
  switch (type) {
    case SupportActions.LOAD_USER_FEEDBACK:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case SupportActions.LOAD_USER_FEEDBACK_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        feedback: payload.results
      });
    case SupportActions.LOAD_USER_FEEDBACK_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case SupportActions.LOAD_USER_FEEDBACK_BY_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case SupportActions.LOAD_USER_FEEDBACK_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        feedbackById: Object.assign({}, state.feedbackById, {
          [payload.id]: payload
        })
      });
    case SupportActions.LOAD_USER_FEEDBACK_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case SupportActions.LOAD_USER_FEEDBACK_HISTORY:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case SupportActions.LOAD_USER_FEEDBACK_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        historyById: Object.assign({}, state.historyById, {
          [payload[0].feedback_id]: payload
        })
      });
    case SupportActions.LOAD_USER_FEEDBACK_HISTORY_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case SupportActions.UPDATE_USER_FEEDBACK:
      return Object.assign({}, state, {
        updating: true,
        error: null
      });
    case SupportActions.UPDATE_USER_FEEDBACK_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
        feedbackById: Object.assign({}, state.feedbackById, {
          [payload.id]: payload
        })
      });
    case SupportActions.UPDATE_USER_FEEDBACK_FAILURE:
      return Object.assign({}, state, {
        updating: false,
        error: payload.error
      });
    case SupportActions.LOAD_USER_FEEDBACK_EVENTS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case SupportActions.LOAD_USER_FEEDBACK_EVENTS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        events: payload
      });
    case SupportActions.LOAD_USER_FEEDBACK_EVENTS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: SupportState) => state.loading;
export const getUpdatingState = (state: SupportState) => state.updating;
export const getFeedback = (state: SupportState) => state.feedback;
export const getFeedbackById = (state: SupportState) => state.feedbackById;
export const getFeedbackHistoryById = (state: SupportState) => state.historyById;
export const getFeedbackEvents = (state: SupportState) => state.events;

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {AddressService} from '../../core/address.service';
import * as AddressActions from '../address/address.actions';
import {catchError, map, switchMap} from 'rxjs/operators';

@Injectable()
export class AddressEffects {
  @Effect() searchAddress$: Observable<Action> = this._actions$.pipe(
    ofType(AddressActions.SEARCH_ADDRESS),
    map((action: AddressActions.SearchAddress) => action.payload),
    switchMap(payload => {
      return this._service.searchAddress(payload)
        .pipe(
          map(state => new AddressActions.SearchAddressSuccess(state)),
          catchError(error => of(new AddressActions.SearchAddressFailure(error)))
        );
    })
  );

  @Effect() loadAddressById$: Observable<Action> = this._actions$.pipe(
    ofType(AddressActions.LOAD_ADDRESS_BY_ID),
    map((action: AddressActions.LoadAddressById) => action.payload),
    switchMap(payload => {
      return this._service.loadById(payload)
        .pipe(
          map(state => new AddressActions.LoadAddressByIdSuccess(state)),
          catchError(error => of(new AddressActions.LoadAddressByIdFailure(error)))
        );
    })
  );

  @Effect() loadCountries$: Observable<Action> = this._actions$.pipe(
    ofType(AddressActions.LOAD_COUNTRIES),
    switchMap(_ => {
      return this._service.loadCountries()
        .pipe(
          map(state => new AddressActions.LoadCountriesSuccess(state)),
          catchError(error => of(new AddressActions.LoadCountriesFailure(error)))
        );
    })
  );

  constructor(private _actions$: Actions, private _service: AddressService) {}
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-nav-bar-back-button, [app-nav-bar-back-button]',
  template: `
    <a [routerLink]="routerLink" class="has-text-white" *ngIf="routerLink">
        <span class="icon">
          <i class="icon ion-md-arrow-back"></i>
        </span>
      {{ label }}
    </a>
  `
})
export class NavBarBackButtonComponent implements OnInit, OnDestroy {
  label: string;
  routerLink: string;

  private _routes: Array<any>;
  private _eventSubscription: Subscription;

  constructor(private _router: Router) {}

  ngOnInit(): void {
    this._routes = this._router.config;

    this._eventSubscription = this._router.events
      .subscribe(
        (event: any) => {
          if (event instanceof NavigationEnd) {
            const path = event.url.split('/');

            if (path.length > 2) {
              const index = this._routes.findIndex(r => r.path === path[1]);
              this.routerLink = this._routes[index].path;
              this.label = this._routes[index].name;
            } else if (path.length === 2) {
              this.routerLink = null;
              this.label = null;
            }
          }
        }
      );
  }

  ngOnDestroy(): void {
    if (this._eventSubscription) {
      this._eventSubscription.unsubscribe();
    }
  }
}

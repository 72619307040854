import {Injectable} from '@angular/core';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map, take} from 'rxjs/operators';
import {HttpHeaders} from '@angular/common/http';

const TOKEN = environment['TOKEN'];

@Injectable()
export class ApiService {

  constructor(protected _ls: LocalStorage) {}

  protected _getAuthHeaders(): Observable<any> {
    return this._ls.getItem(TOKEN)
      .pipe(
        map((token: string) => new HttpHeaders({'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`})),
        take(1)
      );
  }

}

import * as GroupActions from './group.actions';
import {handleError} from '../helpers';
import {Group} from '../../interfaces';

type Action = GroupActions.All;

export interface GroupState {
  loading: boolean;
  entities: Array<Group>;
  entitiesById: any;
  searchResults: Array<Group>;
  adminsByGroupId: Array<any>;
  membersByGroupId: Array<any>;
  groupsByUser: any;
  next: string;
  error: string;
}

const initialState: GroupState = {
  loading: false,
  entities: null,
  entitiesById: null,
  searchResults: null,
  adminsByGroupId: null,
  membersByGroupId: null,
  groupsByUser: null,
  next: null,
  error: null
};

export function reducer(state: GroupState = initialState, {type, payload}: Action): GroupState {
  switch (type) {
    case GroupActions.LOAD_GROUPS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case GroupActions.LOAD_GROUPS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: [...payload.results],
        next: payload.next,
        error: null
      });
    case GroupActions.LOAD_GROUPS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case GroupActions.LOAD_MORE_GROUPS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case GroupActions.LOAD_MORE_GROUPS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: [...state.entities, ...payload.results],
        next: payload.next
      });
    case GroupActions.LOAD_MORE_GROUPS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case GroupActions.LOAD_GROUP_BY_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case GroupActions.LOAD_GROUP_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesById: Object.assign({}, state.entitiesById, {
          [payload.id]: payload
        }),
        error: null
      });
    case GroupActions.LOAD_GROUP_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case GroupActions.SEARCH_GROUP:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case GroupActions.SEARCH_GROUP_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        searchResults: payload.results,
        error: null
      });
    case GroupActions.SEARCH_GROUP_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case GroupActions.CLEAR_GROUP_SEARCH:
      return Object.assign({}, state, {
        loading: false,
        searchResults: null,
        error: null
      });
    case GroupActions.UPDATE_GROUP:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case GroupActions.UPDATE_GROUP_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesById: {
          [payload.id]: Object.assign({}, payload)
        },
        error: null
      });
    case GroupActions.UPDATE_GROUP_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case GroupActions.LOAD_GROUP_ADMINS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case GroupActions.LOAD_GROUP_ADMINS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        adminsByGroupId: {
          [payload.group_id]: payload.admins
        },
        error: null
      });
    case GroupActions.LOAD_GROUP_ADMINS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case GroupActions.ADD_GROUP_ADMINS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case GroupActions.ADD_GROUP_ADMINS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        adminsByGroupId: {
          [payload.group_id]: payload.admins
        },
        error: null
      });
    case GroupActions.ADD_GROUP_ADMINS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case GroupActions.REMOVE_GROUP_ADMIN:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case GroupActions.REMOVE_GROUP_ADMIN_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        adminsByGroupId: {
          [payload.group_id]: payload.admins
        },
        error: null
      });
    case GroupActions.REMOVE_GROUP_ADMIN_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case GroupActions.LOAD_GROUP_MEMBERS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case GroupActions.LOAD_GROUP_MEMBERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        membersByGroupId: {
          [payload.group_id]: payload.members
        },
        error: null
      });
    case GroupActions.LOAD_GROUP_MEMBERS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case GroupActions.ADD_GROUP_MEMBERS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case GroupActions.ADD_GROUP_MEMBERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        membersByGroupId: {
          [payload.group_id]: payload.members
        },
        error: null
      });
    case GroupActions.ADD_GROUP_MEMBERS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case GroupActions.REMOVE_GROUP_MEMBER:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case GroupActions.REMOVE_GROUP_MEMBER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        membersByGroupId: {
          [payload.group_id]: payload.members
        },
        error: null
      });
    case GroupActions.REMOVE_GROUP_MEMBER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case GroupActions.LOAD_GROUPS_BY_USER:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
    case GroupActions.LOAD_GROUPS_BY_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        groupsByUser: Object.assign({}, state.groupsByUser, {
          [payload.user_id]: payload.groups
        }),
      });
    case GroupActions.LOAD_GROUPS_BY_USER_FAILURE:
      return Object.assign({}, state, {
        loading: true,
        error: handleError(payload),
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: GroupState) => state.loading;
export const getEntities = (state: GroupState) => state.entities;
export const getSearchResults = (state: GroupState) => state.searchResults;
export const getEntitiesById = (state: GroupState) => state.entitiesById;
export const getError = (state: GroupState) => state.error;
export const getNext = (state: GroupState) => state.next;
export const getAdminsByGroupId = (state: GroupState) => state.adminsByGroupId;
export const getMembersByGroupId = (state: GroupState) => state.membersByGroupId;
export const getGroupsByUser = (state: GroupState) => state.groupsByUser;

import * as EulaActions from './eula.actions';

type Action = EulaActions.All;

export interface EulaState {
  loading: boolean;
  entities: any[];
  entitiesByVersion: any;
  error: string;
}

const initialState = {
  loading: false,
  entities: null,
  entitiesByVersion: null,
  error: null,
};

export function reducer(state: EulaState = initialState, {type, payload}: Action): EulaState {
  switch (type) {
    case EulaActions.LOAD_EULA:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case EulaActions.LOAD_EULA_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: payload.results
      });
    case EulaActions.LOAD_EULA_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case EulaActions.LOAD_EULA_BY_VERSION:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case EulaActions.LOAD_EULA_BY_VERSION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesByVersion: Object.assign({}, state.entitiesByVersion, {
          [payload.version_number.toString()]: payload
        })
      });
    case EulaActions.LOAD_EULA_BY_VERSION_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case EulaActions.LOAD_EULA_BY_VERSION_AND_LANGUAGE:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case EulaActions.LOAD_EULA_BY_VERSION_AND_LANGUAGE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesByVersion: Object.assign({}, state.entitiesByVersion, {
          [payload.version_number.toString()]: Object.assign({}, state.entitiesByVersion[payload.version_number], {
            html: payload.html
          })
        })
      });
    case EulaActions.LOAD_EULA_BY_VERSION_AND_LANGUAGE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: EulaState) => state.loading;
export const getEntities = (state: EulaState) => state.entities;
export const getEntitiesByVersion = (state: EulaState) => state.entitiesByVersion;
export const getError = (state: EulaState) => state.error;

import {Action} from '@ngrx/store';

export const LOAD_USER_STATISTICS = 'LOAD_USER_STATISTICS';
export const LOAD_USER_STATISTICS_SUCCESS = 'LOAD_USER_STATISTICS_SUCCESS';
export const LOAD_USER_STATISTICS_FAILURE = 'LOAD_USER_STATISTICS_FAILURE';
export const LOAD_INSTITUTION_STATISTICS = 'LOAD_INSTITUTION_STATISTICS';
export const LOAD_INSTITUTION_STATISTICS_SUCCESS = 'LOAD_INSTITUTION_STATISTICS_SUCCESS';
export const LOAD_INSTITUTION_STATISTICS_FAILURE = 'LOAD_INSTITUTION_STATISTICS_FAILURE';
export const LOAD_SERVICE_RECEIVER_LIST = 'LOAD_SERVICE_RECEIVER_LIST';
export const LOAD_SERVICE_RECEIVER_LIST_SUCCESS = 'LOAD_SERVICE_RECEIVER_LIST_SUCCESS';
export const LOAD_SERVICE_RECEIVER_LIST_FAILURE = 'LOAD_SERVICE_RECEIVER_LIST_FAILURE';

export class LoadUserStatistics implements Action {
  readonly type = LOAD_USER_STATISTICS;

  constructor(public payload?: any) {}
}

export class LoadUserStatisticsSuccess implements Action {
  readonly type = LOAD_USER_STATISTICS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadUserStatisticsFailure implements Action {
  readonly type = LOAD_USER_STATISTICS_FAILURE;

  constructor(public payload: string) {}
}

export class LoadInstitutionStatistics implements Action {
  readonly type = LOAD_INSTITUTION_STATISTICS;

  constructor(public payload?: any) {}
}

export class LoadInstitutionStatisticsSuccess implements Action {
  readonly type = LOAD_INSTITUTION_STATISTICS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadInstitutionStatisticsFailure implements Action {
  readonly type = LOAD_INSTITUTION_STATISTICS_FAILURE;

  constructor(public payload: string) {}
}

export class LoadServiceReceiverList implements Action {
  readonly type = LOAD_SERVICE_RECEIVER_LIST;

  constructor(public payload?: any) {}
}

export class LoadServiceReceiverListSuccess implements Action {
  readonly type = LOAD_SERVICE_RECEIVER_LIST_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadServiceReceiverListFailure implements Action {
  readonly type = LOAD_SERVICE_RECEIVER_LIST_FAILURE;

  constructor(public payload: string) {}
}

export type All = LoadUserStatistics | LoadUserStatisticsSuccess | LoadUserStatisticsFailure | LoadInstitutionStatistics |
  LoadInstitutionStatisticsSuccess | LoadInstitutionStatisticsFailure | LoadServiceReceiverList | LoadServiceReceiverListSuccess |
  LoadServiceReceiverListFailure;

import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Component({
  template: `
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <section class="modal-card-body">
          <h1 class="title is-3">{{ title }}</h1>
          
          <p>{{ text }}</p>
          
          <div class="columns margin-top--md">
            <div class="column is-4 is-offset-8">
              <button class="button is-success is-fullwidth" (click)="confirm(true)">Ok</button>
            </div>
          </div>
        </section>
      </div>
    </div>
  `
})
export class AlertModalComponent {
  @Input() title: string;
  @Input() text: string;

  @Output() onButtonClicked: EventEmitter<boolean> = new EventEmitter();

  confirm(result: boolean): void {
    this.onButtonClicked.emit(result);
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.code === 'Escape' || event.keyCode === 27) {
      this.onButtonClicked.emit(true);
    }

    if (event.key === 'Enter' || event.code === 'Enter' || event.keyCode === 13) {
      this.onButtonClicked.emit(true);
    }
  }
}

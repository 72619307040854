import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToastComponent} from './toast.component';

@NgModule({
  declarations: [
    ToastComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ToastComponent
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class ToastModule {}

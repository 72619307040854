import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as SupportActions from './support.actions';
import {SupportService} from '../../core/support.service';
import {ToastError, ToastSuccess} from '../toast/toast.actions';

@Injectable()
export class SupportEffects {
  @Effect() userFeedback$: Observable<Action> = this.actions$.pipe(
    ofType(SupportActions.LOAD_USER_FEEDBACK),
    map((action: SupportActions.LoadUserFeedback) => action.payload),
    switchMap(payload => {
      return this._service.loadUserFeedback(payload)
        .pipe(
          map(state => new SupportActions.LoadUserFeedbackSuccess(state)),
          catchError(error => of(new SupportActions.LoadUserFeedbackFailure(error)))
        );
    })
  );

  @Effect() userFeedbackById$: Observable<Action> = this.actions$.pipe(
    ofType(SupportActions.LOAD_USER_FEEDBACK_BY_ID),
    map((action: SupportActions.LoadUserFeedback) => action.payload),
    switchMap(payload => {
      return this._service.loadUserFeedbackById(payload)
        .pipe(
          map(state => new SupportActions.LoadUserFeedbackByIdSuccess(state)),
          catchError(error => of(new SupportActions.LoadUserFeedbackByIdFailure(error)))
        );
    })
  );

  @Effect() addUserFeedbackHistory$: Observable<Action> = this.actions$.pipe(
    ofType(SupportActions.ADD_USER_FEEDBACK_HISTORY),
    map((action: SupportActions.AddUserFeedbackHistory) => action.payload),
    switchMap(payload => {
      return this._service.addUserFeedbackHistory(payload)
        .pipe(
          map(state => new SupportActions.AddUserFeedbackHistorySuccess(state)),
          catchError(error => of(new SupportActions.AddUserFeedbackHistoryFailure(error)))
        );
    })
  );

  @Effect() loadUserFeedbackHistory$: Observable<Action> = this.actions$.pipe(
    ofType(SupportActions.LOAD_USER_FEEDBACK_HISTORY),
    map((action: SupportActions.LoadUserFeedbackHistory) => action.payload),
    switchMap(payload => {
      return this._service.loadUserFeedbackHistory(payload)
        .pipe(
          map(state => new SupportActions.LoadUserFeedbackHistorySuccess(state)),
          catchError(error => of(new SupportActions.LoadUserFeedbackByIdFailure(error)))
        );
    })
  );

  @Effect() updateUserFeedback$: Observable<Action> = this.actions$.pipe(
    ofType(SupportActions.UPDATE_USER_FEEDBACK),
    map((action: SupportActions.UpdateUserFeedback) => action.payload),
    switchMap(payload => {
      return this._service.updateUserFeedback(payload.feedback_id, payload.status, payload.assigned_to)
        .pipe(
          map(state => new SupportActions.UpdateUserFeedbackSuccess(state)),
          catchError(error => of(new SupportActions.UpdateUserFeedbackFailure(error)))
        );
    })
  );

  @Effect() updateUserFeedbackSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(SupportActions.UPDATE_USER_FEEDBACK_SUCCESS),
    map(() => new ToastSuccess('Updated!')));

  @Effect() updateUserFeedbackFailure$: Observable<Action> = this.actions$.pipe(
    ofType(SupportActions.UPDATE_USER_FEEDBACK_FAILURE),
    map(() => new ToastError('Unable to update!')));



  @Effect() loadUserFeedbackEvents$: Observable<Action> = this.actions$.pipe(
    ofType(SupportActions.LOAD_USER_FEEDBACK_EVENTS),
    switchMap(payload => {
      return this._service.loadUserFeedbackEvents()
        .pipe(
          map(state => new SupportActions.LoadUserFeedbackEventsSuccess(state)),
          catchError(error => of(new SupportActions.LoadUserFeedbackEventsFailure(error)))
        );
    })
  );

  constructor(private actions$: Actions, private _service: SupportService) {}
}

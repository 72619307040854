import {Action} from '@ngrx/store';
import {Address} from '../../interfaces';

export const SEARCH_ADDRESS = 'SEARCH_ADDRESS';
export const SEARCH_ADDRESS_SUCCESS  = 'SEARCH_ADDRESS_SUCCESS';
export const SEARCH_ADDRESS_FAILURE  = 'SEARCH_ADDRESS_FAILURE';
export const CLEAR_ADDRESS_SEARCH = 'CLEAR_ADDRESS_SEARCH';
export const LOAD_ADDRESS_BY_ID = 'LOAD_ADDRESS_BY_ID';
export const LOAD_ADDRESS_BY_ID_SUCCESS  = 'LOAD_ADDRESS_BY_ID_SUCCESS';
export const LOAD_ADDRESS_BY_ID_FAILURE  = 'LOAD_ADDRESS_BY_ID_FAILURE';
export const LOAD_COUNTRIES = 'LOAD_COUNTRIES';
export const LOAD_COUNTRIES_SUCCESS  = 'LOAD_COUNTRIES_SUCCESS';
export const LOAD_COUNTRIES_FAILURE  = 'LOAD_COUNTRIES_FAILURE';

export class SearchAddress implements Action {
  readonly type = SEARCH_ADDRESS;

  constructor(public payload: Address) {}
}

export class SearchAddressSuccess implements Action {
  readonly type = SEARCH_ADDRESS_SUCCESS;

  constructor(public payload: any) {}
}

export class SearchAddressFailure implements Action {
  readonly type = SEARCH_ADDRESS_FAILURE;

  constructor(public payload: string) {}
}

export class ClearAddressSearch implements Action {
  readonly type = CLEAR_ADDRESS_SEARCH;

  constructor(public payload?: string) {}
}

export class LoadAddressById implements Action {
  readonly type = LOAD_ADDRESS_BY_ID;

  constructor(public payload: string) {}
}

export class LoadAddressByIdSuccess implements Action {
  readonly type = LOAD_ADDRESS_BY_ID_SUCCESS;

  constructor(public payload: Address) {}
}

export class LoadAddressByIdFailure implements Action {
  readonly type = LOAD_ADDRESS_BY_ID_FAILURE;

  constructor(public payload: string) {}
}

export class LoadCountries implements Action {
  readonly type = LOAD_COUNTRIES;

  constructor(public payload?: any) {}
}

export class LoadCountriesSuccess implements Action {
  readonly type = LOAD_COUNTRIES_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadCountriesFailure implements Action {
  readonly type = LOAD_COUNTRIES_FAILURE;

  constructor(public payload: string) {}
}

export type All = SearchAddress | SearchAddressSuccess | SearchAddressFailure | ClearAddressSearch | LoadAddressById |
  LoadAddressByIdSuccess | LoadAddressByIdFailure | LoadCountries | LoadCountriesSuccess | LoadCountriesFailure;

import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Group, SelectedEntity} from '../interfaces';

@Injectable()
export class GroupService extends ApiService {

  constructor(private _http: HttpClient, protected _ls: LocalStorage) {
    super(_ls);
  }

  load(): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}groups`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
      );
  }

  loadMore(url: string): Observable<any> {
    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
      );
  }

  loadById(institutionId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}groups/${institutionId}`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers}))
      );
  }

  createGroup(group: Group): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}groups`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.post(url, group, {headers: headers}))
      );
  }

  searchGroups(name: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}groups/search?name=${name}`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
      );
  }

  updateGroup(group: Group): Observable<any> {
    delete group.admins;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => {
          const updateUrl = `${environment['API_URL']}${environment['API_VERSION']}groups/${group.id}`;
          return this._http.patch(updateUrl, group, {headers: headers});
        })
      );
  }

  loadAdmins(groupId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}groups/${groupId}/admins`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
        map(admins => Object.assign({}, {
          group_id: groupId,
          admins: admins
        }))
      );
  }

  addAdmins(groupId: string, userIds: string[]): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}groups/${groupId}/admins`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.post(url, {admin_ids: userIds}, {headers: headers})),
        map(admins => Object.assign({}, {
          group_id: groupId,
          admins: admins
        }))
      );
  }

  removeAdmin(groupId: string, userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}groups/${groupId}/admins/${userId}`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.delete(url, {headers: headers})),
        map(admins => Object.assign({}, {
          group_id: groupId,
          admins: admins
        }))
      );
  }

  loadMembers(groupId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}groups/${groupId}/members`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
        map(members => Object.assign({}, {
          group_id: groupId,
          members: members
        }))
      );
  }

  addMembers(groupId: string, userIds: string[]): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}groups/${groupId}/members`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.post(url, {member_ids: userIds}, {headers: headers})),
        map(members => Object.assign({}, {
          group_id: groupId,
          members: members
        }))
      );
  }

  removeMember(groupId: string, userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}groups/${groupId}/members/${userId}`;

    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.delete(url, {headers: headers})),
        map(members => Object.assign({}, {
          group_id: groupId,
          members: members
        }))
      );
  }

  loadUserGroups(userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users/${userId}/groups`;
    return this._getAuthHeaders()
      .pipe(
        switchMap((headers: HttpHeaders) => this._http.get(url, {headers: headers})),
        map(groups => Object.assign({}, {
          user_id: userId,
          groups: groups
        }))
      );
  }

  addUserToGroups(userId: string, selected: SelectedEntity[]): Observable<any> {
    const observables = selected.map((s: SelectedEntity) => {
      if (s.relation_type === 'ADMIN') {
        return this.addAdmins(s.id, [userId]);
      } else if (s.relation_type === 'MEMBER') {
        return this.addMembers(s.id, [userId]);
      }
    });

    return forkJoin(observables);
  }
}

import {NgModule} from '@angular/core';
import {NavbarComponent} from './navbar/navbar.component';
import {CommonModule} from '@angular/common';
import {MenuComponent} from './menu/menu.component';
import {RouterModule} from '@angular/router';
import {NavBarBackButtonComponent} from './navbar/nav-bar-back-button.component';

@NgModule({
  declarations: [
    NavbarComponent,
    MenuComponent,
    NavBarBackButtonComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    NavbarComponent,
    MenuComponent
  ]
})
export class NavModule {}
